import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import type { Entity } from './types';
import { filterRequestStrings } from '~/utils/filter-request-strings';

const BASE = '/api/admin/banner';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  return getListResponse<Entity>(`${BASE}/list?${pagination}`, activePage);
});

export const get = request.card<Entity>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Entity }>(`${BASE}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: res.data?.data,
  };
});

export const update = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  filterRequestStrings(data.data);
  const body = { ...data.data };

  if (Array.isArray(body.desktopImage)) {
    body.desktopImage = body.desktopImage[0];
  } else {
    delete body.desktopImage;
  }

  if (Array.isArray(body.mobileImage)) {
    body.mobileImage = body.mobileImage[0];
  } else {
    delete body.mobileImage;
  }

  const res = await api.patch<{ data: Entity }>(`${BASE}/update/${data.id}`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.data };
});
