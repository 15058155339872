import { ft } from '~/utils/feature-translate';

export const loginRu = {
  title: 'Вход',
  submitButton: 'Войти',
  fields: {
    login: {
      placeholder: '',
      label: 'Логин',
    },
    password: {
      placeholder: '',
      label: 'Пароль',
    },
  },
};

export const t = ft(loginRu, 'login.');
