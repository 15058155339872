import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Бренды',
    table_title: 'Бренды',
    card_title: 'Редактирование бренда',
  },
  table: {
    create_new: 'Создать',
    not_found: 'Бренды не найдены',
    remove_many: 'Удалить выбранные',

    title: 'Заголовок',
    image: 'Изображение',
    active: { label: 'Активирован' },
    search: 'Поиск',
  },
  form: {
    name: { label: 'Название', placeholder: 'Новые коллекции' },
    slug: { label: 'ЧПУ-название', placeholder: 'ruchki-krasniye' },
    external: { label: 'ID бренда со старого сайта или импорта', placeholder: 'ruchki-krasniye' },
    image: { label: 'Обложка' },
    h1: { label: 'Заголовок (H1)', placeholder: 'Pierre Balmain' },
    meta_title: { label: 'Название страницы (Title)', placeholder: 'Pierre Balmain' },
    meta_keywords: { label: 'Ключевые слова (Keywords)', placeholder: 'Pierre Balmain' },
    meta_description: { label: 'Описание (Description)', placeholder: 'Описание' },
    seo: { label: 'SEO' },
    content_top: 'Наполнение над товарами',
    content_bottom: 'Наполнение под товарами',
    alternatives: { label: 'Альтернативны', placeholder: 'SPS', no_data_text: 'Введите синоним и нажмите Enter' },
  },
};

export const t = ft(ru, 'catalog.brands.');
