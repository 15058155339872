import { request } from '~/utils/request';
import { PricesEntity } from './type';

const BASE = '/api/admin/prices';

export const get = request.card(async ({ api, parseError }) => {
  const res = await api.get(`${BASE}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  res.data.data.items.map((item: PricesEntity, itemIndex: number) => {
    item.tabId = `id-${itemIndex}`;
    item.table = [];
    item.tableColumns = [
      {
        prop: 'area',
        label: item.parameter,
      },
    ];

    /**
     * создаем колонки для таблицы
     */
    item.emission.map((emission: [number, number | null], emissionIndex: number) => {
      item.tableColumns.push({
        prop: `${emissionIndex}`,
        label: emission[1] ? `${emission[0]}-${emission[1]}` : `≥${emission[0]}`,
      });
    });

    if (item.minimalPrices.length) {
      item.tableColumns.push({
        prop: 'minPrice',
        label: 'Минималка',
      });
    }

    if (item.areaBased) {
      /**
       * создаем ряды для таблицы
       */
      item.areas.map((area: number, areaIndex: number) => {
        const columns: Record<string, number> = {
          area: area === 2147483647 ? '' : area,
        };

        item.emission.map((emission: [number, number | null], emissionIndex: number) => {
          columns[emissionIndex] = item.prices[areaIndex][emissionIndex];
        });

        /**
         * в конец таблицы надо добавить минимальные цены
         */
        if (item.minimalPrices.length) {
          item.minimalPrices.map((minPrice) => {
            if (minPrice.maxArea === area) {
              columns.minPrice = minPrice.value.inRubles;
            }
          });
        }

        item.table.push(columns);
      });
    } else {
      /**
       * создаем ряды для таблицы
       */
      Object.entries(item.variants).map((area: [string, string], areaIndex: number) => {
        const columns: Record<string, number> = {
          area: area[1],
        };

        item.emission.map((emission: [number, number | null], emissionIndex: number) => {
          columns[emissionIndex] = item.prices[areaIndex][emissionIndex];
        });

        /**
         * в конец таблицы надо добавить минимальные цены
         */
        if (item.minimalPrices.length) {
          item.minimalPrices.map((minPrice) => {
            if (minPrice.variant === area[1]) {
              columns.minPrice = minPrice.value.inRubles;
            }
          });
        }

        item.table.push(columns);
      });
    }
  });

  return { data: res.data.data };
});

export const update = request.card(async ({ api, data, parseError }) => {
  /**
   * Сборка JSON для отправки на бэк
   */
  const outJson: any = {};
  data.data.items.map((item: PricesEntity) => {
    if (item.table) {
      item.table.map((row: Record<string, string | number>, rowIndex: number) => {
        Object.entries(row).map((col: [string, string | number], colIndex: number) => {
          if (col[0] === 'minPrice') {
            item.minimalPrices.map((minPrice) => {
              if (minPrice.variant === row.area) {
                minPrice.value.inRubles = +col[1];
                minPrice.value.inKopecks = minPrice.value.inRubles * 100;
              }
            });
          } else {
            item.prices[rowIndex][colIndex] = +col[1];
          }
        });
      });
    }

    outJson[item.title] = {
      title: item.title,
      parameter: item.parameter,
      sort: item.sort,
      areaBased: item.areaBased,
      minimalPrice: item.minimalPrice,
      minimalPrices: item.minimalPrices.map((minPrice) => [
        minPrice.minArea,
        minPrice.maxArea,
        minPrice.variant,
        minPrice.option,
        minPrice.value.inRubles,
      ]),
      areas: item.areas,
      emission: item.emission,
      prices: item.prices,
      variants: Object.values(item.variants),
      options: item.options.map((option) => ({
        title: option.title,
        type: option.type,
        value: option.value,
      })),
    };
  });

  const res = await api.post(`${BASE}`, outJson);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data.data.items };
});
