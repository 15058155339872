import { request } from '~/utils/request';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import { getListResponse } from '~/utils/get-list';
import type { Entity } from './types';

const BASE = '/api/admin/blog/post';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  return getListResponse<Entity>(`${BASE}/list?${pagination}`, activePage);
});

export const search = request.custom<Entity[]>(async () => {
  const pagination = 'limit=50&page=1';
  const res = await getListResponse<Entity>(`${BASE}/list?${pagination}`);
  return { data: res.data.rows };
});

export const get = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Entity }>(`${BASE}/read/${data.id}`, data.data);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  const resData = {
    ...res.data?.data,
    h1: res.data?.data?.metaInfo?.h1 || '',
    metaTitle: res.data?.data?.metaInfo?.metaTitle || '',
    metaKeywords: res.data?.data?.metaInfo?.metaKeywords || '',
    metaDescription: res.data?.data?.metaInfo?.metaDescription || '',
  };

  return { data: resData };
});

export const create = request.card<Entity, Entity>(async ({
  api, data, parseError, router,
}) => {
  filterRequestStrings(data.data);
  const publishedAt = data.data?.publishedAt ? new Date(data.data?.publishedAt) : new Date();

  if (publishedAt) {
    publishedAt.setDate(publishedAt.getDate() + 1);
  }

  const body = {
    ...data.data,
    metaInfo: {}, // Должен быть пустым
    publishedAt: publishedAt.toJSON(),
  };

  if (data.data?.h1 || data.data?.h1 === null) body.metaInfo.h1 = data.data?.h1;
  if (data.data?.metaTitle || data.data?.metaTitle === null) body.metaInfo.metaTitle = data.data?.metaTitle;
  if (data.data?.metaKeywords || data.data?.metaKeywords === null) body.metaInfo.metaKeywords = data.data?.metaKeywords;
  if (data.data?.metaDescription || data.data?.metaDescription === null) body.metaInfo.metaDescription = data.data?.metaDescription;

  delete body.h1;
  delete body.metaTitle;
  delete body.metaKeywords;
  delete body.metaDescription;

  if (Array.isArray(body.previewImage)) {
    body.previewImage = body.previewImage[0];
  }

  const res = await api.post<{ data: Entity }>(`${BASE}/create`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  router.push({ name: 'blog' });
  return { data: res.data?.data };
});

export const update = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  filterRequestStrings(data.data);

  const body = {
    ...data.data,
    metaInfo: {}, // Должен быть пустым
  };

  if (data.data?.publishedAt) {
    const publishedAt = new Date(data.data?.publishedAt);

    if (publishedAt) {
      publishedAt.setDate(publishedAt.getDate() + 1);
    }

    body.publishedAt = publishedAt.toJSON();
  }

  if (data.data?.h1 || data.data?.h1 === null) body.metaInfo.h1 = data.data?.h1;
  if (data.data?.metaTitle || data.data?.metaTitle === null) body.metaInfo.metaTitle = data.data?.metaTitle;
  if (data.data?.metaKeywords || data.data?.metaKeywords === null) body.metaInfo.metaKeywords = data.data?.metaKeywords;
  if (data.data?.metaDescription || data.data?.metaDescription === null) body.metaInfo.metaDescription = data.data?.metaDescription;

  delete body.h1;
  delete body.metaTitle;
  delete body.metaKeywords;
  delete body.metaDescription;

  if (Array.isArray(body.previewImage)) {
    body.previewImage = body.previewImage[0];
  } else {
    delete body.previewImage;
  }

  const res = await api.patch<{ data: Entity }>(`${BASE}/update/${data.id}`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.data };
});

export const batch = request.custom<boolean, { ids: string[] }>(async ({ data, api, parseError }) => {
  const res = await api.post(`${BASE}/delete`, { ids: data.ids });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: true,
  };
});

export const remove = request.card<boolean, Entity>(async ({ data }) => batch({
  ids: [data.id as string],
}));
