import { loginPageController } from 'magner';
import loginRequest from './requests';
import { t } from './ru';

const loginPageConfig = loginPageController({
  title: t('title'),
  request: loginRequest,
  logo: '/logo.svg',
  form: {
    submitOnEnter: true,
    fullDataOnUpdate: true,

    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: t('submitButton'),
        },
      },
    ],
    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'input',
          name: 'login',
          label: t('fields.login.label'),
          props: {
            type: 'text',
            placeholder: t('fields.login.placeholder'),
            autocomplete: 'on',
          },
          validation: {
            type: 'empty',
            trigger: 'blur',
          },
        },
        {
          type: 'input',
          name: 'password',
          label: t('fields.password.label'),
          props: {
            type: 'password',
            autocomplete: 'on',
            placeholder: t('fields.password.placeholder'),
          },
          // validation: {
          //   type: 'password',
          //   trigger: 'blur',
          // },
        },
      ],
    },
  },
});

export default loginPageConfig;
