import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import type { Entity } from './types';

const BASE = '/api/admin/property';

export const search = request.custom<Entity[]>(async () => {
  const pagination = 'limit=50&page=1';
  const res = await getListResponse<Entity>(`${BASE}/list?${pagination}`);
  return { data: res.data.rows };
});
