<template>
  <div
    class="el-cascader--container"
    @click="loadOnClick"
  >
    <ElCascader
      :class="{ 'el-cascader--container-loading': loading }"
      :model-value="val"
      :options="options"
      :props="field"
      :show-all-levels="field.showAllLevels || false"
      :filterable="field.filterable || false"
      :clearable="field.clearable || false"
      :placeholder="field.removePlaceHolder ? ' ' : field.placeholder"
      @change="changeVal"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  defineEmits,
  defineProps, onMounted,
  ref, watch,
} from 'vue';
import { ElCascader } from 'magner/element-plus';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: [Array, Number],
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue', 'loadOnClickFinal']);

const val = ref(props.modelValue);
const options = ref([]);
const loading = ref<boolean>(false);

const changeVal = (newVal: any) => {
  val.value = newVal;
  let value: number[] | number | null = null;

  if (newVal) {
    if (props.field.multiple) {
      value = newVal.flatMap((item: number[]) => item[item.length - 1]);
    } else {
      value = newVal[newVal.length - 1];
    }
  }

  emit('update:modelValue', value);
};

const initOptions = async () => {
  options.value = [];
  const res = await props.field.remoteMethod();

  if (res.data) {
    options.value = res.data;
  }
};

const loadOnClick = async () => {
  loading.value = true;
  if (props.field.loadOnClick) {
    await initOptions();
    emit('loadOnClickFinal', true);
  }
  loading.value = false;
};

onMounted(async () => {
  if (props.field.initOnMount) {
    await initOptions();
  }
});

watch(() => props.modelValue, () => {
  val.value = props.modelValue;
});
</script>

<style src="./cascader.css" />
