import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import type { BatchRequestData, Entity } from './types';
import { FilterList } from '~/types';

export interface FilterSize {
  id: number,
  slug: string,
  title: string,
}

const BASE = '/api/admin/category';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;
  const search = data.filters.title ? `&filters[0][id]=search&filters[0][value]=${data.filters.title}` : '';
  const active = data.filters.active ? '&filters[1][id]=activeOnly&filters[1][value]=1' : '';

  const sort = `&sort[0][id]=sort&sort[0][value]=${data.sort.sort ?? 'ASC'}`;

  return getListResponse<Entity>(`${BASE}/list?${pagination}${search}${active}${sort}`, activePage);
});

export const hierarchySearch = request.custom<Entity[]>(async (data) => {
  const pagination = 'limit=999&page=1';
  const searchQuery = data.data?.search ? `&filters[0][id]=search&filters[0][value]=${data.data?.search}` : '';
  const isActiveQuery = data.data?.isActive ? '&filters[1][id]=activeOnly&filters[1][value]=1' : '';
  const res = await getListResponse<Entity>(`${BASE}/hierarchy?${pagination}${searchQuery}${isActiveQuery}`);

  return { data: res.data.rows };
});

export const hierarchySearchOnlyActive = request.custom<Entity[]>(async (data) => {
  const pagination = 'limit=999&page=1';
  const searchQuery = data.data?.search ? `&filters[1][id]=search&filters[1][value]=${data.data?.search}` : '';
  const res = await getListResponse<Entity>(`${BASE}/hierarchy?${pagination}&filters[0][id]=activeOnly&filters[0][value]=1'${searchQuery}`);

  return { data: res.data.rows };
});

export const listSearch = request.custom<Entity[]>(async (data) => {
  const pagination = 'limit=999&page=1';
  const searchQuery = data.data?.search ? `&filters[0][id]=search&filters[0][value]=${data.data?.search}` : '';
  const isActiveQuery = data.data?.isActive ? '&filters[1][id]=activeOnly&filters[1][value]=1' : '';
  const res = await getListResponse<Entity>(`${BASE}/list?${pagination}${searchQuery}${isActiveQuery}`);

  return { data: res.data.rows };
});

export const listSearchOnlyActive = request.custom<{ title: string, id: number }[]>(async (data) => {
  const localSearch = data.data?.search || '';
  const searchQuery = data?.data?.form?.title ? `search=${data?.data?.form?.title}` : '';
  const supplierQuery = data?.data?.form?.supplier ? `supplier=${data?.data?.form?.supplier}` : '';
  const brandQuery = data?.data?.form?.brand ? `brand=${data?.data?.form?.brand}` : '';
  const activeQuery = data?.data?.form.active ? 'activeOnly=1' : '';

  const queries: string[] = [searchQuery, supplierQuery, brandQuery, activeQuery].filter((item) => !!item);

  const res = await request.api.get(`/api/admin/filter/list?${queries.join('&')}`);

  if (res?.data?.data?.items) {
    const items: FilterList = res.data.data.items;

    const categories = items.categories.filter((item) => (localSearch
      ? item.title.toLowerCase().includes(localSearch.toLowerCase())
      : item
    ));

    return { data: categories.length ? categories : [{ id: 1, title: 'Ничего не найдено', disabled: true }] };
  }

  return { data: [{ id: 1, title: 'Ошибка на сервере', disabled: true }] };
});

export const get = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Entity }>(`${BASE}/read/${data.id}`, data.data);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  const resActiveFilterSizes = await api
    .get<{ data: { items: Array<FilterSize> } }>('/api/admin/filter_size');

  let activeFilterSizes: Array<FilterSize> = [];
  let sizeFilterActive = false;

  if (resActiveFilterSizes.data) {
    activeFilterSizes = resActiveFilterSizes.data.data?.items ?? [];
    sizeFilterActive = !!(activeFilterSizes.find((item) => item.slug === res.data?.data?.slug) ?? false);
  }

  const resData = {
    ...res.data?.data,
    h1: res.data?.data?.metaInfo?.h1 || '',
    metaTitle: res.data?.data?.metaInfo?.metaTitle || '',
    metaKeywords: res.data?.data?.metaInfo?.metaKeywords || '',
    metaDescription: res.data?.data?.metaInfo?.metaDescription || '',
    menuTitle: res.data?.data?.metaInfo?.menuTitle || '',
    detailTextTop: res.data?.data?.metaInfo?.detailTextTop || '',
    detailTextBottom: res.data?.data?.metaInfo?.detailTextBottom || '',
    sizeFilterActive,
    activeFilterSizes,
  };

  delete resData.metaInfo;

  return { data: resData };
});

export const create = request.card<Entity, Entity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  filterRequestStrings(data.data);

  const body = {
    ...data.data,
    metaInfo: {}, // Должен быть пустым
  };

  if (data.data?.h1 || data.data?.h1 === null) body.metaInfo.h1 = data.data?.h1;
  if (data.data?.metaTitle || data.data?.metaTitle === null) body.metaInfo.metaTitle = data.data?.metaTitle;
  if (data.data?.metaKeywords || data.data?.metaKeywords === null) body.metaInfo.metaKeywords = data.data?.metaKeywords;
  if (data.data?.metaDescription || data.data?.metaDescription === null) body.metaInfo.metaDescription = data.data?.metaDescription;
  if (data.data?.menuTitle || data.data?.menuTitle === null) body.metaInfo.menuTitle = data.data?.menuTitle;
  if (data.data?.detailTextTop || data.data?.detailTextTop === null) body.metaInfo.detailTextTop = data.data?.detailTextTop;
  if (data.data?.detailTextBottom || data.data?.detailTextBottom === null) body.metaInfo.detailTextBottom = data.data?.detailTextBottom;

  delete body.h1;
  delete body.metaTitle;
  delete body.metaKeywords;
  delete body.metaDescription;
  delete body.menuTitle;
  delete body.detailTextTop;
  delete body.detailTextBottom;

  // Когда загружаем фото из element-plus передается массив с id загруженного изображения
  if (Array.isArray(body.image) && body.image.length) {
    body.image = body.image[0];
  } else {
    body.image = null;
  }

  const res = await api.post<{ data: Entity }>(`${BASE}/create`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  router.push({ name: 'categories' });
  return { data: res.data?.data };
});

export const update = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  filterRequestStrings(data.data);

  const body = {
    ...data.data,
    metaInfo: {}, // Должен быть пустым
  };

  if (data.data?.h1 || data.data?.h1 === null) body.metaInfo.h1 = data.data?.h1;
  if (data.data?.metaTitle || data.data?.metaTitle === null) body.metaInfo.metaTitle = data.data?.metaTitle;
  if (data.data?.metaKeywords || data.data?.metaKeywords === null) body.metaInfo.metaKeywords = data.data?.metaKeywords;
  if (data.data?.metaDescription || data.data?.metaDescription === null) body.metaInfo.metaDescription = data.data?.metaDescription;
  if (data.data?.menuTitle || data.data?.menuTitle === null) body.metaInfo.menuTitle = data.data?.menuTitle;
  if (data.data?.detailTextTop || data.data?.detailTextTop === null) body.metaInfo.detailTextTop = data.data?.detailTextTop;
  if (data.data?.detailTextBottom || data.data?.detailTextBottom === null) body.metaInfo.detailTextBottom = data.data?.detailTextBottom;

  delete body.h1;
  delete body.metaTitle;
  delete body.metaKeywords;
  delete body.metaDescription;
  delete body.menuTitle;
  delete body.detailTextTop;
  delete body.detailTextBottom;

  if (body.sizeFilterActive) {
    if (!body.activeFilterSizes?.find((item) => item.slug === body.slug)) {
      body.activeFilterSizes?.push({ id: body.id as number, title: body.title, slug: body.slug });
    }
  } else {
    body.activeFilterSizes = body.activeFilterSizes?.filter((item) => item.slug !== body.slug);
  }
  const filterSizeIds = body.activeFilterSizes?.map((item) => item.id);
  await api.patch<{ data: { items: Array<FilterSize> } }>('/api/admin/filter_size/update', { ids: filterSizeIds });

  delete body.id;
  delete body.sizeFilterActive;
  delete body.activeFilterSizes;

  // Когда загружаем фото из element-plus передается массив с id загруженного изображения
  if (Array.isArray(body.image) && body.image.length) {
    body.image = body.image[0];
  } else if (typeof body.image === 'object' && body.image?.id) {
    body.image = body.image?.id;
  } else {
    body.image = null;
  }

  const res = await api.patch<{ data: Entity }>(`${BASE}/update/${data.id}`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.data };
});

export const batch = request.custom<boolean, BatchRequestData>(async ({ data, api, parseError }) => {
  const res = await api.post(`${BASE}/batch/${data.type}`, { ids: data.ids });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: true,
  };
});

export const remove = request.card<boolean, Entity>(async ({ data }) => batch({
  ids: [data.id as string],
  type: 'delete',
}));
