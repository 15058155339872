import { initMagner } from 'magner';
import { supplierAllSearch } from 'features/catalog/supplier';

import project from '~/configs';

import 'magner/style';
import '~/assets/styles/index.css';

import { ENVIRONMENT } from '~/constants';

console.warn('Environment is:', ENVIRONMENT);

initMagner(project);

supplierAllSearch({ search: '' })
  .then((res) => {
    if (res.data?.length) {
      localStorage.setItem('defaultSupplierId', `${res.data[0].id}`);
    }
  });
