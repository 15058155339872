<template>
  <div
    v-if="props.row?.products"
    class="category-cascader-product"
  >
    <div class="category-cascader__title">
      {{ title }}
    </div>
    <Cascader
      v-model="modelValue"
      :field="{
        remoteMethod: () => categoryHierarchySearch({ isActive: true }),
        checkStrictly: true,
        value: 'id',
        label: 'title',
        children: 'categories',
        loadOnClick: true,
        removePlaceHolder: !!title,
      }"
      @update:modelValue="setCategory"
      @load-on-click-final="title = ''"
    />
    <div class="category-cascader-product__remove">
      <RemoveBtn
        v-show="modelValue && unMappable"
        @click.prevent="removeCategory"
      />
    </div>
  </div>
  <div v-else>
    ↑
  </div>
</template>

<script lang="ts" setup>
import { magnerMessage } from 'magner';
import { categoryHierarchySearch } from 'features/catalog/categories';
import {
  computed,
  PropType,
  ref,
  watchEffect,
} from 'vue';
import { removeParentCategory, setParentCategory } from 'features/catalog-import/products/requests';
import { Entity } from '../types';
import Cascader from '~/components/cascader/cascader.vue';
import RemoveBtn from '~/components/remove-btn.vue';

// eslint-disable-next-line no-undef
const props = defineProps({
  row: {
    type: Object as PropType<Entity>,
    required: true,
  },
});

const modelValue = ref<null | number>(null);
const title = ref<null | string>(null);
const unMappable = computed<null | boolean>(() => props.row?.unMappable);

const setCategory = async () => {
  const res = await setParentCategory({
    categoryId: modelValue.value,
    ids: [props.row.id],
    supplierId: props.row.supplierId,
  });

  if (res.data) {
    magnerMessage({
      type: 'success',
      message: 'Успешно изменено',
    });
    return;
  }

  modelValue.value = null;
  title.value = null;

  magnerMessage({
    type: 'error',
    message: res.error?.message,
  });
};

const removeCategory = async () => {
  const res = await removeParentCategory({
    ids: [props.row.id],
    supplierId: props.row.supplierId,
  });

  if (res.data) {
    magnerMessage({
      type: 'success',
      message: 'Рубрика основного каталога успешно удалена',
    });

    modelValue.value = null;
    title.value = null;
    return;
  }

  magnerMessage({
    type: 'error',
    message: 'Ошибка удаления категории',
  });
};

watchEffect(() => {
  modelValue.value = props.row.category?.id ?? null;
  title.value = props.row.category?.title ?? '';
});
</script>

<style lang="postcss">
.category-cascader-product {
  position: relative;
  display: flex;

  &__title {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    left: 11px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__remove {
    width: 26px;
    margin: 0 0 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-input__suffix-inner {
    pointer-events: none !important;
  }
}
</style>
