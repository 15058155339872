type ReturnArgs = {
  refreshToken: string,
  token: string,
}

const refreshRequest = async (
  baseUrl: string,
  refreshToken: string,
): Promise<{ data: ReturnArgs } | null> => {
  const res = await fetch(`${baseUrl}/auth/refresh`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refreshToken }),
  });

  if (res.status === 200) {
    const { authenticated } = await res.json();

    return {
      data: {
        ...authenticated,
      },
    };
  }

  return null;
};

export default refreshRequest;
