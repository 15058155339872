import type { BatchRequestData } from 'features/catalog/categories/types';
import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import { Entity, EntityData } from './types';
import { filterRequestStrings } from '~/utils/filter-request-strings';

const BASE = '/api/admin/slide';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  return getListResponse<Entity>(`${BASE}/list?${pagination}`, activePage);
});

export const get = request.card(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Entity }>(`${BASE}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const create = request.card<Entity, EntityData>(async ({
  data, api, parseError, router,
}) => {
  filterRequestStrings(data.data);
  const body = { ...data.data };

  if (Array.isArray(body.image)) {
    body.image = body.image[0];
  }

  if (Array.isArray(body.mobileImage)) {
    body.mobileImage = body.mobileImage[0];
  }

  const res = await api.post<{ data: Entity }>(`${BASE}/create`, {
    ...body,
    type: 'main',
    active: true,
    sort: 1,
  });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  router.push({ name: 'slider' });
  return { data: res.data?.data };
});

export const update = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  filterRequestStrings(data.data);
  const body = { ...data.data };

  if (Array.isArray(body.image)) {
    body.image = body.image[0];
  } else {
    delete body.image;
  }

  if (Array.isArray(body.mobileImage)) {
    body.mobileImage = body.mobileImage[0];
  } else {
    delete body.mobileImage;
  }

  const res = await api.patch<{ data: Entity }>(`${BASE}/update/${data.id}`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.data };
});

export const batch = request.custom<boolean, BatchRequestData>(async ({ data, api, parseError }) => {
  const res = await api.post(`${BASE}/batch/${data.type}`, { ids: data.ids });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: true };
});

export const remove = request.card<boolean, Entity>(async ({ data }) => batch({
  ids: [data.id as string],
  type: 'delete',
}));
