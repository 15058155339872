import { request } from '~/utils/request';
import type { Entity } from './types';

const BASE = '/api/admin/minimal_order_price';

let minPrices: Entity[] = [];

const proxyResponseToData = (data: Entity[]) => {
  const res: Record<number, number> = {};
  data.forEach((item) => {
    res[item.city] = item.value;
  });
  return res;
};

const proxyDataToUpdate = (data: Record<number, number>) => ({
  values: Object.entries(data).map(([city, value]) => ({
    city: +city,
    value: +value,
    name: 'minimal.order.price',
  })),
});

export const get = request.card(async ({ api, parseError }) => {
  const res = await api.get(`${BASE}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  minPrices = res.data.data.items;
  return { data: proxyResponseToData(minPrices) };
});

export const update = request.card(async ({ api, data, parseError }) => {
  const updateData = proxyDataToUpdate(data.data);

  const res = await api.patch(`${BASE}/update`, updateData);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  minPrices = res.data.data.items;
  return { data: proxyResponseToData(minPrices) };
});
