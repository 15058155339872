import { request } from '~/utils/request';
import type { Entity } from './types';

const BASE = '/api/admin/exports';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const res = await request.api.get(`${BASE}/list?${pagination}`);

  return {
    data: {
      rows: res.data?.data.items,
      pagination: {
        currentPage: activePage,
        totalPages: res.data?.data.countPages || 1,
        totalItems: res.data?.data.items.length,
      },
    },
  };
});
