import { tablePageController, filtersFormController, actionTable } from 'magner';
import type { Entity } from './types';
import { read, batch } from './requests';
import { t } from './ru';
import { formatShortDate } from '~/utils/format-date';

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('pages.table_title'),
  },

  request: read,

  table: {
    rowSelectable: {
      rowIdKey: 'id',
      actions: [
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<Entity>(async ({ data }) => {
            const ids = data.selected.map((item) => item.id);
            const res = await batch({ ids });
            return !!res.error;
          }),
          props: {
            text: t('table.remove_many'),
            type: 'danger',
          },
        },
      ],
    },
    emptyText: t('table.not_found'),
    rowLink: (row) => (
      {
        name: 'news-edit',
        params: { id: row.id },
      }
    ),
    columns: [
      {
        prop: 'title',
        label: t('table.title'),
      },
      {
        prop: 'previewText',
        label: t('table.lead'),
      },
      {
        prop: 'publishedAt',
        label: t('table.publishedAt'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (cellValue) => formatShortDate(cellValue as string),
        },
      },
      {
        prop: 'active',
        label: t('table.active'),
        width: 150,
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue ? 'Да' : 'Нет'),
        },
      },
    ],
  },

  filters: filtersFormController<Entity>({
    saveToLocalStorage: true,

    actions: [
      {
        type: 'link',
        to: () => ({ name: 'news-edit', params: { id: 'new' } }),
        props: {
          type: 'primary',
          plain: true,
          text: t('table.create_new'),
        },
      },
    ],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      title: '',
    },

    sort: {
      publishedAt: '',
    },

    layout: [
      {
        type: 'input',
        name: 'title',
        props: {
          placeholder: t('table.search'),
          inputDelay: 400,
        },
      },
    ],
  }),
});
