import { request } from '~/utils/request';
import type { Entity } from './types';
import { CategoryItem } from './types';

const BASE = '/api/admin/footer_categories';

export const get = request.card<Entity, Entity>(async ({ api }) => {
  const res = await api.get<{ data: { items: CategoryItem[] } }>(BASE);
  if (res.error) {
    return { data: { items: [] } };
  }
  return {
    data: {
      items: res.data?.data.items,
    },
  };
});

export const update = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  const body = {
    values: data?.data?.items && data.data.items.length ? data.data.items.map((item) => item.id) : [],
  };

  const res = await api.patch(`${BASE}/update`, body);
  if (res.error) {
    const parsed = parseError(res.error);
    const fieldError = parsed.fields?.values;
    if (fieldError) parsed.message = fieldError;

    return { error: parsed };
  }
  return { data: res.data };
});
