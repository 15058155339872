import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Категории в подвале',
    card_title: 'Категории в подвале',
  },
};

export const t = ft(ru, 'settings.footer_categories.');
