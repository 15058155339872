import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    table_title: 'Добавление товаров',
    card_title: 'Редактирование товара',
  },
  table: {
    create_new: 'Создать',
    not_found: 'Товары не найдены',
    remove_many: 'Деактивировать выбранное',
    add_many: 'Добавить выбранное',
    search: 'Поиск',

    title: 'Название',
    image: 'Изображение',
    sku: 'Артикул',
    brand: 'Бренд',
    supplier: 'Поставщик',
    active: 'Активирован',
  },
};

export const t = ft(ru, 'catalog.editor_modal.');
