<template>
  <ElRow class="select-list">
    <ElSelect
      :placeholder="'Выбрать категорию'"
      :loading="loading"
      :loading-text="'Загрузка'"
      :filterable="true"
      :remote="true"
      :remote-method="remoteMethod"
      value-key="id"
      @change="selectCategory"
    >
      <ElOption
        v-for="option in allCategories"
        :key="option.id"
        :value="option.id"
        :label="option.title"
      />
    </ElSelect>
  </ElRow>
  <ElTable :data="selectedCategories">
    <ElTableColumn>
      <template #default="{row}">
        <ElCol>
          <ElButton
            class="select-list__button-arrow"
            :disabled="row.sort === 1"
            @click="() => changeSortPosition(row, 'up')"
          >
            <svg viewBox="0 0 1024 1024">
              <path
                d="M104.704 685.248a64 64 0 0 0 90.496 0l316.8-316.8 316.8 316.8a64 64 0 0 0 90.496-90.496L557.248 232.704a64 64 0 0 0-90.496 0L104.704 594.752a64 64 0 0 0 0 90.496z" />
            </svg>
          </ElButton>
          <ElButton
            class="select-list__button-arrow"
            :disabled="row.sort === selectedCategories.length"
            @click="() => changeSortPosition(row, 'down')"
          >
            <svg viewBox="0 0 1024 1024">
              <path d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z" />
            </svg>
          </ElButton>
        </ElCol>
      </template>
    </ElTableColumn>
    <ElTableColumn label="Название">
      <template #default="{row}">{{ row.name || row.title }}</template>
    </ElTableColumn>
    <ElTableColumn label="ЧПУ">
      <template #default="{row}">{{ row.slug || '' }}</template>
    </ElTableColumn>
    <ElTableColumn width="160">
      <template #default="{row}">
        <ElButton type="danger" plain @click="removeCategory(row)">
          Удалить
        </ElButton>
      </template>
    </ElTableColumn>
  </ElTable>
</template>

<script lang="ts" setup>
import {
  defineEmits,
  defineProps, onMounted,
  PropType,
  ref,
} from 'vue';
import type { InputField } from 'magner';
import {
  ElRow,
  ElSelect,
  ElOption,
  ElTableColumn,
  ElTable,
  ElButton,
  ElCol,
} from 'magner/element-plus';
import { CategoryEntity, categoryListSearch } from 'features/catalog/categories';
import './select-list.css';
import { CategoryItem } from 'features/settings/footer-categories/types';

type SelectListProps = InputField<any>['props'] & {};

const props = defineProps({
  field: {
    type: Object as PropType<SelectListProps>,
    required: true,
  },
  modelValue: {
    type: [Array, String] as PropType<CategoryItem[]>,
    required: true,
  },
});

const emit = defineEmits(['blur', 'action', 'update:modelValue']);

const allCategories = ref<CategoryEntity[]>([]);
const selectedCategories = ref<(CategoryEntity | CategoryItem)[]>(props.modelValue || []);
const loading = ref(false);

const setSortPosition = () => {
  selectedCategories.value.forEach((item, index) => {
    item.sort = index + 1;
  });
};

const selectCategory = (id: number) => {
  const selected = allCategories.value.find((category) => category.id === id);
  const alreadySelected = selectedCategories.value.some((category) => category.id === id);
  if (!selected || alreadySelected) return;

  selectedCategories.value = [
    selected,
    ...selectedCategories.value,
  ];

  allCategories.value = allCategories.value.filter((item) => item.id !== id);

  setSortPosition();
  emit('update:modelValue', selectedCategories.value);
};

const removeCategory = (category: CategoryEntity) => {
  selectedCategories.value = selectedCategories.value.filter((item) => item.id !== category.id);
  emit('update:modelValue', selectedCategories.value);
};

const remoteMethod = async (search = '') => {
  loading.value = true;
  const newOptions = await categoryListSearch({ search });
  allCategories.value = newOptions.data?.filter((option) => selectedCategories.value
    .every((category) => category.id !== option.id)) || [];
  loading.value = false;
};

const changeSortPosition = async (
  category: CategoryEntity,
  to: 'up' | 'down',
) => {
  if (to === 'up') {
    const indexCategory = selectedCategories.value.findIndex((item) => item.id === category.id);
    const upCategory = selectedCategories.value[indexCategory - 1];
    if (!upCategory) return;
    selectedCategories.value[indexCategory - 1] = category;
    selectedCategories.value[indexCategory] = upCategory;
  }

  if (to === 'down') {
    const indexCategory = selectedCategories.value.findIndex((item) => item.id === category.id);
    const downCategory = selectedCategories.value[indexCategory + 1];
    if (!downCategory) return;
    selectedCategories.value[indexCategory + 1] = category;
    selectedCategories.value[indexCategory] = downCategory;
  }

  setSortPosition();
  emit('update:modelValue', selectedCategories.value);
};

await remoteMethod('');

onMounted(() => {
  setSortPosition();
});
</script>
