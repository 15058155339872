import { tablePageController, tableController, filtersFormController } from 'magner';
import { read } from './requests';
import { t } from './ru';
import type { Entity } from './types';

export const tableConfig = tablePageController({
  header: {
    title: t('table.title'),
  },
  request: read,
  table: tableController<Entity>({
    emptyText: t('table.not_found'),
    rowLink: (row) => (
      {
        name: 'banner',
        params: { id: row.type },
      }
    ),
    columns: [
      {
        prop: 'title',
        label: t('table.banner_title'),
      },
      {
        prop: 'description',
        label: t('table.description'),
      },
      {
        prop: 'desktopImage',
        label: t('table.desktop_image'),
        view: {
          type: 'image',
          formatter: (_, row) => row.desktopImage || '/empty.png',
        },
      },
      {
        prop: 'mobileImage',
        label: t('table.mobile_image'),
        view: {
          type: 'image',
          formatter: (_, row) => row.mobileImage || '/empty.png',
        },
      },
    ],
  }),

  filters: filtersFormController<Entity>({
    saveToLocalStorage: true,

    actions: [],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      name: '',
      phone: '',
    },

    sort: {},

    layout: [],
  }),
});
