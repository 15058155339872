import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Новости',
    table_title: 'Новости',
    card_title: 'Редактирование новости',
  },
  table: {
    create_new: 'Создать',
    not_found: 'Новости не найдены',
    remove_many: 'Удалить выбранное',
    active_many: 'Активировать выбранное',
    search: 'Поиск',

    title: 'Заголовок',
    lead: 'Лид',
    active: 'Активен',
    publishedAt: 'Дата публикации',
  },
  form: {
    name: { label: 'Название', placeholder: 'Новые коллекции' },
    description: { label: 'Короткое описание', placeholder: 'Кружки, ручки, свистелки' },
    publishedAt: { label: 'Дата публикации', placeholder: '02.08.2022' },
    slug: { label: 'ЧПУ-название', placeholder: 'ruchki-krasniye' },
    content: 'Содержание новости',
    active: 'Активна',
    cover: { label: 'Обложка' },

    h1: { label: 'Заголовок (H1)', placeholder: 'Корпоративные подарки' },
    meta_title: { label: 'Название страницы (Title)', placeholder: 'Корпоративные подарки' },
    meta_keywords: { label: 'Ключевые слова (Keywords)', placeholder: 'Подарки' },
    meta_description: { label: 'Описание (Description)', placeholder: 'Описание' },
    seo: { label: 'SEO' },
  },
};

export const t = ft(ru, 'content.news.');
