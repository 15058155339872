import { cardPageController } from 'magner';
import { brandAllSearch } from 'features/catalog/brands';
import { saveImageContext } from 'features/common/file-request';
import { supplierAllSearch } from 'features/catalog/supplier';
import { propertySearch } from 'features/catalog/properties';
import GroupLinks from 'features/catalog/products/components/group-links.vue';
import { categoryOnlyActiveHierarchySearch } from 'features/catalog/categories';
import { baseT } from 'configs/translation/base';
import Cascader from '~/components/cascader/cascader.vue';
import type { Entity } from './types';
import { t } from './ru';
import {
  get,
  update,
  remove,
  create,
} from './requests';
import './product.css';

export const cardConfig = cardPageController<Entity>({
  header: {
    title: t('pages.card_title'),
    tabs: [
      {
        label: t('form.tabs.product'),
        link: { name: 'product' },
        active: true,
      },
      {
        label: t('form.tabs.variations'),
        link: { name: 'product-variations' },
        active: false,
        hideIfNew: true,
      },
    ],
  },

  createRequest: create,
  getRequest: get,
  updateRequest: update,
  deleteRequest: remove,

  form: {
    actions: [
      {
        type: 'link',
        to: { name: 'products' },
        props: {
          text: baseT('form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'row',
          props: {},
          fields: [
            {
              type: 'custom',
              name: 'productsInGroup',
              component: () => GroupLinks,
              props: {},
            },
          ],
        },

        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'title',
                  label: t('form.name.label'),
                  props: {
                    required: true,
                    placeholder: t('form.name.placeholder'),
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                },
                {
                  type: 'input',
                  name: 'sku',
                  label: t('form.sku.label'),
                  props: {
                    required: true,
                    placeholder: t('form.sku.placeholder'),
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                },
                {
                  type: 'input',
                  name: 'externalId',
                  label: t('form.external.label'),
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'input',
                  name: 'minVariationsPrice',
                  label: t('form.min_price.label'),
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'textarea',
                  name: 'description',
                  label: t('form.description.label'),
                  props: {
                    required: true,
                    placeholder: t('form.description.placeholder'),
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                },
                {
                  type: 'dropzone',
                  name: 'images',
                  label: t('form.image.label'),
                  props: {
                    required: true,
                    multiple: true,
                    removable: true,
                    valueKey: 'id',
                    srcKey: 'downloadUrl',
                    saveToBackend: saveImageContext('product_image'),
                    inputAtts: {
                      accept: 'image/png, image/jpeg, image/webp',
                    },
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                },
              ],
            },
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: { span: 12, xs: 24, styles: { 'padding-left': '12px' } },
          layout: [
            /** Category, Brand, Supplier */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'custom',
                  name: 'category',
                  component: () => Cascader,
                  label: t('form.category.label'),
                  props: {
                    required: true,
                    checkStrictly: true,
                    value: 'id',
                    label: 'title',
                    children: 'categories',
                    remoteMethod: categoryOnlyActiveHierarchySearch,
                    filterable: true,
                    initOnMount: true,
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                },
                {
                  type: 'custom',
                  name: 'categories',
                  component: () => Cascader,
                  label: t('form.second_category.label'),
                  props: {
                    multiple: true,
                    checkStrictly: true,
                    value: 'id',
                    label: 'title',
                    children: 'categories',
                    remoteMethod: categoryOnlyActiveHierarchySearch,
                    filterable: true,
                    initOnMount: true,
                  },
                },
                {
                  type: 'select',
                  name: 'brand',
                  label: t('form.brand.label'),
                  options: [],
                  props: {
                    valueKey: 'id',
                    labelKey: 'title',
                    filterable: true,
                    remote: true,
                    remoteMethod: brandAllSearch,
                    placeholder: t('form.brand.placeholder'),
                  },
                },
                {
                  type: 'select',
                  name: 'supplier',
                  label: t('form.supplier.label'),
                  options: [],
                  props: {
                    valueKey: 'id',
                    labelKey: 'title',
                    filterable: true,
                    remote: true,
                    remoteMethod: supplierAllSearch,
                    placeholder: t('form.supplier.placeholder'),
                  },
                },
                {
                  type: 'input',
                  name: 'active',
                  props: {
                    hidden: true,
                  },
                },
                {
                  type: 'input',
                  name: 'activeTitle',
                  label: t('form.active.label'),
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'input',
                  name: 'causeOfDeactivation',
                  label: t('form.cause_of_deactivation.label'),
                  props: {
                    hidden: ({ state }) => state.active,
                    readOnly: true,
                  },
                },
              ],
            },

            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'switch',
                  name: 'draft',
                  dataType: 'boolean',
                  label: t('form.draft.label'),
                  props: {},
                },
              ]
            },

            {
              type: 'row',
              props: {
                elementsGrow: true,
              },
              fields: [
                {
                  type: 'switch',
                  name: 'recommended',
                  dataType: 'boolean',
                  label: t('form.recommended.label'),
                  props: {},
                },
                {
                  type: 'switch',
                  name: 'ignoreStock',
                  dataType: 'boolean',
                  label: t('form.ignore_stock.label'),
                  props: {},
                },
                {
                  type: 'switch',
                  name: 'importDisabled',
                  dataType: 'boolean',
                  label: t('form.import_disabled.label'),
                  props: {},
                },
              ],
            },

            {
              type: 'column',
              title: t('form.properties.title'),
              props: { span: 24, isPaper: true, titleType: 'heading' },
              fields: [
                {
                  type: 'collection',
                  name: 'propertyValues',
                  dataType: 'array',
                  props: {
                    class: 'collection-properties',
                    showFirst: true,
                    firstRemovable: true,
                  },
                  layout: [
                    {
                      type: 'row',
                      props: { elementsGrow: true },
                      fields: [
                        {
                          type: 'select',
                          name: 'id',
                          label: t('form.properties.type'),
                          options: [],
                          props: {
                            valueKey: 'id',
                            labelKey: 'title',
                            filterable: true,
                            remote: true,
                            remoteMethod: propertySearch,
                          },
                        },
                        {
                          type: 'input',
                          name: 'value',
                          label: t('form.properties.value'),
                          props: {},
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'createdAt',
                  label: t('form.created_at.label'),
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'input',
                  name: 'lastImportedAt',
                  label: t('form.last_imported_at.label'),
                  props: {
                    readOnly: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
