import {
  tablePageController,
  filtersFormController,
  tableController,
  actionTable,
} from 'magner';
import { read } from './requests';
import { t } from './ru';
import type { Entity } from './types';

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('pages.table_title'),
  },

  request: read,

  table: tableController<Entity>({
    rowSelectable: {
      rowIdKey: 'id',
      actions: [
        {
          type: 'action',
          emits: 'deselect-and-success',
          props: {
            text: t('table.mapping_added'),
            type: 'primary',
          },
          action: actionTable<Entity>(async (data) => {
            const hosts = data.data.selected.map((item) => item.id);
            const event = new CustomEvent(
              'map_properties', {
                detail: { hosts },
              },
            );

            document.dispatchEvent(event);
            return false;
          }),
        },
      ],
    },
    emptyText: t('table.not_found'),
    columns: [
      {
        prop: 'name',
        label: t('table.title'),
        sortable: true,
      },
    ],
  }),

  filters: filtersFormController({
    saveToLocalStorage: false,

    actions: [],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      name: '',
    },

    sort: {},

    layout: [
      {
        type: 'input',
        name: 'name',
        props: {
          placeholder: t('table.search'),
          inputDelay: 400,
        },
      },
    ],
  }),
});
