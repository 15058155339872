import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Минимальная стоимость заказа',
    card_title: 'Минимальная стоимость заказа',
  },
  form: {
    '1': { title: 'Санкт-Петербург' },
    '2': { title: 'Москва' },
    '3': { title: 'Россия' },
    '4': { title: 'Другой город' },
    placeholder: '1000',
  },
};

export const t = ft(ru, 'settings.min_price_order.');
