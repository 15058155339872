<template>
  <ElRow v-if="modelValue && modelValue.length" class="group-links">
    <GroupLink
      v-for="product in modelValue.slice(0, 3)"
      :key="product.id"
      :product="product"
    />

    <template v-if="show">
      <GroupLink
        v-for="product in modelValue.slice(4)"
        :key="product.id"
        :product="product"
      />
    </template>

    <ElButton
      v-if="modelValue.length > 3"
      type="primary"
      plain
      class="group-links__more"
      @click="show = !show"
    >
      Показать {{ show ? 'меньше' : 'больше' }}
    </ElButton>
  </ElRow>
</template>

<script lang="ts" setup>
import {
  defineProps,
  ref,
  PropType,
} from 'vue';
import type { InputField } from 'magner';
import { ElRow, ElButton } from 'magner/element-plus';
import type { GroupProduct } from 'features/catalog/products/types';
import GroupLink from 'features/catalog/products/components/group-link.vue';
import './links.css';

type LinksProps = InputField<any>['props'] & {};

defineProps({
  field: {
    type: Object as PropType<LinksProps>,
    required: true,
  },
  modelValue: {
    type: Array as PropType<GroupProduct[]>,
    required: true,
  },
});

const show = ref(false);
</script>
