import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Прайсы нанесения',
    card_title: 'Прайсы нанесения',
  },
};

export const t = ft(ru, 'settings.prices.');
