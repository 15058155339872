import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Акции',
    table_title: 'Акции',
    card_title: 'Создание / Редактирование акции',
  },
  table: {
    not_found: 'Акции не найдены',
    remove_many: 'Удалить выбранное',
    create_new: 'Создать',

    title: 'Заголовок',
    lead: 'Лид',
    active: 'Активна',
  },
  form: {
    name: { label: 'Название', placeholder: 'Бесплатная доставка' },
    description: { label: 'Короткое описание', placeholder: 'Лучшая акция' },
    publishedAt: { label: 'Дата публикации', placeholder: '02.08.2022' },
    slug: { label: 'ЧПУ-название', placeholder: 'besplatnaya-dostavka' },
    content: 'Описание акции',
    cover: { label: 'Обложка' },
    active: 'Активна',

    h1: { label: 'Заголовок (H1)', placeholder: 'Акция' },
    meta_title: { label: 'Название страницы (Title)', placeholder: 'Название страницы' },
    meta_keywords: { label: 'Ключевые слова (Keywords)', placeholder: 'Ключевые слова' },
    meta_description: { label: 'Описание (Description)', placeholder: 'Описание' },
    seo: { label: 'SEO' },
  },
};

export const t = ft(ru, 'content.promotions.');
