import { request } from '~/utils/request';
import type { Entity, SupportedPages } from './types';

const BASE = '/api/admin/static_page';

export const get = (type: SupportedPages) => request.card<Entity, Entity>(async ({ api, parseError }) => {
  const res = await api.get<{ data: Entity }>(`${BASE}/read/${type}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data!.data };
});

export const update = (type: SupportedPages) => request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  const res = await api.patch<{ data: Entity }>(`${BASE}/update/${type}`, data.data);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data!.data };
});
