import { USER_ROLES } from 'features/users/users';
import { t, ru as contentPagesRu } from './ru';
import { getCardConfig as getContentPagesCardConfig } from './card';
import type { SupportedPages } from './types';

export { contentPagesRu, getContentPagesCardConfig };

const getContentPageRoute = (name: SupportedPages) => ({
  type: 'preset' as 'preset',
  preset: {
    preset: 'card' as 'card',
    config: getContentPagesCardConfig(name),
  },
  route: {
    name: `content-${name}`,
    path: `/content/pages/${name}`,
    roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
    visible: true,
    title: t(`sidebar.title.${name}`),
  },
});

export const contentPageRoutes = {
  sidebarGroup: ([
    'payment', 'obmen-vozvrat-tovarov', 'public-offer',
    'privacy', 'agreement', 'requirements', 'about',
  ] as SupportedPages[])
    .map((page) => `content-${page}`),
  routes: [
    getContentPageRoute('about'),
    getContentPageRoute('payment'),
    getContentPageRoute('obmen-vozvrat-tovarov'),
    getContentPageRoute('privacy'),
    getContentPageRoute('public-offer'),
    getContentPageRoute('agreement'),
    // getContentPageRoute('requirements'),
  ],
};

const DELIVERY_PAGES: SupportedPages[] = ['delivery', 'delivery_msk'];

export const deliveryPageRoutes = {
  sidebarGroup: DELIVERY_PAGES.map((item) => `content-${item}`),
  routes: DELIVERY_PAGES.map((item) => getContentPageRoute(item)),
};
