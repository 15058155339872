<template>
  <div class="image-load">
    <label>Ссылка на изображение</label>
    <div v-loading="uploadingName" class="image-load__link">
      <p
        v-if="!uploadingName && link"
        class="loaded"
        @click="copy"
      >
        {{ link }}
      </p>
      <p v-else>Выберите изображение</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  watch, defineProps, ref, PropType,
} from 'vue';
import { magnerNotify } from 'magner';
import { saveImage } from 'features/common/file-request';
import type { Entity } from './types';

const props = defineProps({
  form: {
    type: Object as PropType<Entity>,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
});

const uploadingName = ref<string | null>(null);
const link = ref<string | null>(null);
const copied = ref(false);

const upload = async (file: File) => {
  uploadingName.value = file.name;
  const res = await saveImage(file);
  uploadingName.value = null;

  if (res.error) {
    link.value = null;
    return;
  }

  link.value = res.data!.thumb1600;
};

watch(() => props.form.image, () => {
  const file = props.form.image?.[0];
  if (!file) {
    link.value = null;
    return null;
  }

  if (uploadingName.value && uploadingName.value === file.name) return null;

  upload(file);
  return '';
});

const copy = () => {
  if (copied.value) return;
  navigator.clipboard.writeText(link.value || '').then(() => {
    copied.value = true;
    magnerNotify({
      title: 'Текст скопирован!',
      type: 'info',
      message: 'Текст скопирован!',
    });

    setTimeout(() => {
      copied.value = false;
    }, 3000);
  });
};
</script>

<style src="./image-load.css"></style>
