import { ft } from '~/utils/feature-translate';
import type { Entity } from './types';
import { CityType } from './types';

export const ru = {
  sidebar: {
    title: {
      spb: 'Санкт-Петербург',
      msk: 'Москва',
      ru: 'Россия',
      other: 'Другие города',
    } as Record<CityType, string>,
  },
  pages: {
    spb: 'Контакты - Санкт-Петербург',
    msk: 'Контакты - Москва',
    ru: 'Контакты - Россия',
    other: 'Контакты - Другие города',
  } as Record<CityType, string>,
  form: {
    phone: { label: 'Номер телефона компании', placeholder: '' },
    general_email: { label: 'Email для общих вопросов', placeholder: '' },
    heads_email: { label: 'Email для связи с руководством', placeholder: '' },
    operation_mode: { label: 'Режим работы', placeholder: 'Пн-Пт с 9:00 до 17:00' },
    legal_address: { label: 'Юридический адрес', placeholder: '' },

    office_address: { label: 'Адрес работы контактного центра', placeholder: '' },

    office_cityAddress: { label: 'Город контактного центра', placeholder: 'Город' },
    office_streetAddress: { label: 'Адрес контактного центра', placeholder: 'Улица, дом' },
    office_postalCode: { label: 'Почтовый индекс контактного центра', placeholder: '193214' },

    coordinates: { label: 'Адрес на карте', placeholder: '55.784046,37.584748' },
    receiver_bank: { label: 'Банк получателя', placeholder: '' },
    inn: { label: 'ИНН', placeholder: '' },
    kpp: { label: 'КПП', placeholder: '' },
    ogrn: { label: 'ОГРН', placeholder: '' },
    bik: { label: 'БИК', placeholder: '' },
    ks: { label: 'Корреспондентский счет', placeholder: '' },
    rs: { label: 'Расчетный счет', placeholder: '' },
  },
};

export const t = ft(ru, 'contacts.');

export const formT = (
  key: keyof Entity,
  type: 'label' | 'placeholder',
) => t(`form.${key.replaceAll('.', '_')}.${type}` as any);
