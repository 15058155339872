import addSpaces from './addSpaces';

const getFormatPrice = (minPrice: number): string => {
  const price = `${minPrice}`;
  const priceDotIndex = price.split('')
    .findIndex((letter) => letter === '.');

  if (priceDotIndex > -1) {
    return `${addSpaces(price.slice(0, priceDotIndex))}<span class="pennies">${price.slice(priceDotIndex)}</span>&nbsp₽`;
  }

  return `${addSpaces(minPrice)}&nbsp₽`;
};

export default getFormatPrice;
