import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Товары',
    table_title: 'Товары',
  },
  table: {
    not_found: 'Товары не найдены',

    name: 'Название',
    sku: 'Артикул',
    brand: 'Бренд',
    supplier: 'Поставщик',
    search: 'Поиск по товару',
    categories: 'Рубрика основного каталога',
    category_filter: 'Категория',
    category_supplier: 'Категория поставщика',
    unmapped_only_filter: 'Только незалинкованные',
    last_imported_at: 'Дата и время последнего импорта',
  },
};

export const t = ft(ru, 'catalog_imports.products.');
