import {
  actionTable, filtersFormController, tableController, tablePageController,
} from 'magner';
import { baseT } from 'configs/translation/base';
import { read, batch } from './requests';
import { t } from './ru';
import type { Entity } from './types';

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('table.title'),
  },
  request: read,
  table: tableController<Entity>({
    rowSelectable: {
      rowIdKey: 'id',
      actions: [
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<Entity>(async ({ data }) => {
            const ids = data.selected.map((item) => item.id);
            const res = await batch({ ids: ids as unknown as string[], type: 'activate' });
            return !!res.error;
          }),
          props: {
            text: t('table.active_many'),
            type: 'primary',
          },
        },
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<Entity>(async ({ data }) => {
            const ids = data.selected.map((item) => item.id);
            const res = await batch({ ids: ids as unknown as string[], type: 'deactivate' });
            return !!res.error;
          }),
          props: {
            text: t('table.remove_many'),
            type: 'danger',
          },
        },
      ],
    },
    emptyText: t('table.not_found'),
    rowLink: (row) => (
      {
        name: 'slide',
        params: { id: row.id },
      }
    ),
    columns: [
      {
        prop: 'title',
        label: t('table.slider_title'),
      },
      {
        prop: 'description',
        showOverflowTooltip: true,
        label: t('table.description'),
      },
      {
        prop: 'link',
        label: t('table.link'),
      },
      {
        prop: 'linkText',
        label: t('table.link_text'),
      },
      {
        prop: 'image',
        label: t('table.image'),
        view: {
          type: 'image',
          formatter: (_, row) => row.image || '/empty.png',
        },
      },
      {
        prop: 'sort',
        label: t('table.sort'),
      },
      {
        prop: 'active',
        label: t('table.active'),
        width: 150,
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue ? 'Да' : 'Нет'),
        },
      },
    ],
  }),
  filters: filtersFormController<Entity>({
    saveToLocalStorage: true,

    actions: [
      {
        type: 'link',
        to: { name: 'slide', params: { id: 'new' } },
        props: {
          type: 'primary',
          text: baseT('form.create_new_button'),
        },
      },
    ],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      name: '',
      phone: '',
    },

    sort: {},

    layout: [],
  }),
});
