<template>
  <Cascader
    v-model="modelValue"
    :field="{
      remoteMethod: search,
      checkStrictly: true,
      value: 'id',
      label: 'name',
      children: 'categories',
      loadOnClick: true,
      filterable: true,
      clearable: true,
      placeholder: 'Категория',
      initOnMount: true,
    }"
    @update:modelValue="setFilter"
  />
</template>

<script lang="ts" setup>
import {
  defineEmits,
  defineProps,
  PropType,
  ref, watch,
} from 'vue';
import { categorySupplierSearch } from 'features/catalog-import/categories';
import Cascader from '~/components/cascader/cascader.vue';

const props = defineProps({
  field: {
    type: Object as PropType<{}>,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  form: {
    type: Object as any,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const modelValue = ref<null | string>(props.modelValue);

const search = () => categorySupplierSearch({ search: '', form: props.form });

const setFilter = async () => {
  emit('update:modelValue', modelValue.value ? modelValue.value : '');
};

watch(() => props.modelValue, () => {
  modelValue.value = props.modelValue;
});
</script>
