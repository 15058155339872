import { Warehouse } from 'features/catalog/products/variations/types';
import { USER_ROLES, UserResponse } from 'features/users/users/types';
import { request } from '~/utils/request';
import getUserRole from '~/utils/get-user-role';

const profileRequest = request.profile(async (
  {
    api,
    parseError,
    lstorage,
  },
) => {
  const res = await api.get<{ data: UserResponse }>('/api/profile');

  if (res.error) {
    lstorage.delete('token');
    api.instance.defaults({ headers: {} }, false);
    return { error: parseError(res.error) };
  }

  const role = getUserRole(res.data?.data.roles);
  const token = lstorage.read('token');
  request.api.instance.defaults({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const resWareHouses = await request.api.get<{ data: { elements: Warehouse[] } }>('/api/admin/warehouse/list');
  lstorage.put('wareHouses', resWareHouses.data?.data.elements);

  return {
    data: {
      user: res.data?.data,
      role,
    },
  };
});

export default profileRequest;
