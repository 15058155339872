import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Базовые материалы',
    table_title: 'Базовые материалы',
    card_title: 'Редактирование материала',
  },
  table: {
    create_new: 'Создать',
    not_found: 'Базовые материалы не найдены',
    remove_many: 'Удалить выбранные',
    mapping_added: 'Добавить сопоставления',

    title: 'Название',
    alternatives: 'Альтернативные названия',

    search: 'Поиск',
  },
  form: {
    confirm_delete: 'Вы действительно хотите удалить материал?',

    name: {
      label: 'Название материала',
      placeholder: 'Хлопок',
    },

    alternatives: {
      label: 'Альтернативные названия',
    },
  },
};

export const t = ft(ru, 'mappings.base_materials.');
