import { request } from '~/utils/request';
import type { Entity, EntityResponse, EntityData } from './types';

const fakeData: Entity = {
  phone: '',
  'general.email': '',
  'heads.email': '',
  'operation.mode': '', // режим работы
  'legal.address': '',

  'office.address': '',

  'office.cityAddress': '',
  'office.streetAddress': '',
  'office.postalCode': '',

  coordinates: '',
  'receiver.bank': '',
  inn: '',
  kpp: '',
  ogrn: '',
  bik: '',
  ks: '', // корр счет
  rs: '', // респ счет
};

const proxyResponseToData = (response: EntityResponse): Entity => {
  const res = {
    ...fakeData,
    ...response.data.items
      .reduce((accum, currentValue) => {
        if (Object.hasOwn(fakeData, currentValue.name)) {
          accum[currentValue.name as keyof Entity] = currentValue.value;
        }
        return accum;
      }, {} as Entity),
  };

  try {
    const officeAddress = JSON.parse(res['office.address']);
    res['office.cityAddress'] = officeAddress['office.cityAddress'];
    res['office.streetAddress'] = officeAddress['office.streetAddress'];
    res['office.postalCode'] = officeAddress['office.postalCode'];
  } catch (e) {
    console.error(e);
  }

  return res;
};
const proxyDataToRequest = (data: Entity): Omit<EntityData, 'title'>[] => {
  data['office.address'] = JSON.stringify({
    'office.cityAddress': data['office.cityAddress'],
    'office.streetAddress': data['office.streetAddress'],
    'office.postalCode': data['office.postalCode'],
  });

  delete data['office.cityAddress'];
  delete data['office.streetAddress'];
  delete data['office.postalCode'];

  return Object
    .entries(data)
    .map(([name, value]) => ({
      name,
      value,
    }));
};

const BASE = '/api/admin/contacts';

export const get = (id: number) => request.card<Entity, Entity>(async ({ api }) => {
  const res = await api.get(`${BASE}/${id}`);
  if (res.error) {
    return { data: fakeData };
  }
  return { data: proxyResponseToData(res.data) };
});

export const update = (id: number) => request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  const proxied = { values: proxyDataToRequest(data.data) };

  const res = await api.patch(`${BASE}/${id}/update`, proxied);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  location.reload();

  return { data: proxyResponseToData(res.data) };
});
