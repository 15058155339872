import { cardPageController } from 'magner';
import { saveImage } from 'features/common/file-request';
import { baseT } from 'configs/translation/base';
import type { Entity } from './types';
import { t } from './ru';
import {
  get, update, remove, create,
} from './requests';
import { EditorField } from '~/utils/editor';
import urlToTranslit from '~/utils/url-to-translit';
import AlternativesSelect from '~/components/synonyms-select.vue';

export const cardConfig = cardPageController<Entity>({
  header: {
    title: t('pages.card_title'),
  },

  createRequest: create,
  getRequest: get,
  updateRequest: update,
  deleteRequest: remove,

  form: {
    actions: [
      {
        type: 'link',
        to: { name: 'brands' },
        props: {
          text: baseT('form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'title',
                  label: t('form.name.label'),
                  props: {
                    required: true,
                    placeholder: t('form.name.placeholder'),
                  },
                  changeAction: (data) => {
                    if (data.isNew) data.form.slug = urlToTranslit(data.form.title);
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                },
                {
                  type: 'input',
                  name: 'slug',
                  label: t('form.slug.label'),
                  props: {
                    required: true,
                    placeholder: t('form.slug.placeholder'),
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                },
                {
                  type: 'input',
                  name: 'externalId',
                  label: t('form.external.label'),
                  props: {
                    required: true,
                    placeholder: t('form.external.placeholder'),
                  },
                },
                {
                  type: 'dropzone',
                  name: 'image',
                  label: t('form.image.label'),
                  props: {
                    valueKey: 'id',
                    srcKey: 'downloadUrl',
                    saveToBackend: saveImage,
                    removable: true,
                    inputAtts: {
                      accept: 'image/png, image/jpeg, image/webp',
                    },
                  },
                },
                {
                  type: 'custom',
                  name: 'alternatives',
                  label: t('form.alternatives.label'),
                  component: () => AlternativesSelect,
                  props: {
                    required: true,
                    placeholder: 'Adidas',
                    noDataText: 'Введите и нажмите Enter',
                  },
                },
              ],
            },
          ],
        },
        /** Right column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            {
              type: 'column',
              title: t('form.seo.label'),
              props: {
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'input',
                  name: 'h1',
                  label: t('form.h1.label'),
                  props: {
                    placeholder: t('form.h1.placeholder'),
                  },
                },
                {
                  type: 'textarea',
                  name: 'metaTitle',
                  label: t('form.meta_title.label'),
                  props: {
                    placeholder: t('form.meta_title.placeholder'),
                  },
                },
                {
                  type: 'input',
                  name: 'metaKeywords',
                  label: t('form.meta_keywords.label'),
                  props: {
                    placeholder: t('form.meta_keywords.placeholder'),
                  },
                },
                {
                  type: 'textarea',
                  name: 'metaDescription',
                  label: t('form.meta_description.label'),
                  props: {
                    placeholder: t('form.meta_description.placeholder'),
                  },
                },
              ],
            },
          ],
        },

        {
          type: 'column',
          title: t('form.content_top'),
          props: {
            isPaper: true,
            titleType: 'heading',
            class: 'editor-js',
          },
          fields: [
            {
              type: 'custom',
              name: 'detailTextTop',
              component: () => EditorField,
              props: {
                id: 'editor-category-detail-text-top',
              },
            },
          ],
        },
        {
          type: 'column',
          title: t('form.content_bottom'),
          props: {
            isPaper: true,
            titleType: 'heading',
            class: 'editor-js',
          },
          fields: [
            {
              type: 'custom',
              name: 'detailTextBottom',
              component: () => EditorField,
              props: {
                id: 'editor-category-detail-text-bottom',
              },
            },
          ],
        },
      ],
    },
  },
});
