import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import type { Entity } from './types';
import { ProductGroup } from '~/types/product';

const BASE = '/api/admin/product';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;
  const search = data.filters.title ? `&filters[0][id]=search&filters[0][value]=${data.filters.title}` : '';

  return getListResponse<Entity>(`${BASE}/list?${pagination}${search}`, activePage, data.filters.active);
});

export const getProductGroupsByIds = request.custom<ProductGroup[]>(async ({ data }: { data: number[] }) => {
  const queriesId = data.map((id) => `groupIds[]=${id}`).join('&');

  try {
    const result = await request.api.get(`/api/product/list/by_group_ids?${queriesId}`);
    return { data: result.data.data.items };
  } catch (e) {
    return { data: [] };
  }
});
