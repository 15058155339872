import { request } from '~/utils/request';
import type { Entity } from './types';
import { getListResponse } from '~/utils/get-list';

const BASE = '/api/admin/import-goods/category';

export const search = request.custom<Entity[], { search: string, form: { supplierId: number } }>(async (data) => {
  const searchQuery = data.data?.search ? `&filters[1][id]=search&filters[1][value]=${data.data?.search}` : '';
  const res = await request.api.get<{ data: { elements: Entity[] } }>(
    `${BASE}/list?filters[0][id]=supplierId&filters[0][value]=${data.data.form.supplierId}${searchQuery}`,
  );

  if (res.error) return { data: [] };

  return { data: res.data?.data.elements };
});

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;
  const supplier = data.filters.supplierId ? `&filters[0][id]=supplierId&filters[0][value]=${data.filters.supplierId}` : '';

  return getListResponse<Entity>(`${BASE}/list?${pagination}${supplier}`, activePage);
});

// @ts-ignore
export const setParentCategory = request.custom<Entity>(async ({ data, parseError }) => {
  const res = await request.api.post<{ data: { items: Entity[] } }>(`${BASE}/map`, { ...data });

  if (res.data) {
    return { data: res.data };
  }

  return { error: parseError(res.error) };
});

// @ts-ignore
export const removeParentCategory = request.custom<Entity>(async ({ data, parseError }) => {
  const res = await request.api.post<{ data: { success: true } }>(`${BASE}/unmap`, { ...data });

  if (res.data) {
    return { data: res.data };
  }

  return { error: parseError(res.error) };
});
