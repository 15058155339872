<template>
  <ElCol class="title-component">
    <ElSelect
      v-if="tags.length"
      default-first-option
      placeholder="Добавить shortcode"
      :filterable="true"
      @change="addTag"
    >
      <ElOption
        v-for="option in tags"
        :key="option[0]"
        :value="option[0]"
        :label="option[1]"
      />
    </ElSelect>

    <MagnerFormTextarea
      :model-value="text"
      :field="descriptionField"
      @update:modelValue="updText"
    />
  </ElCol>
</template>

<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  ref,
  watchEffect,
  PropType,
} from 'vue';
import { MagnerFormTextarea, type TextareaField } from 'magner';
import { ElCol, ElSelect, ElOption } from 'magner/element-plus';
import type { SupportedDynamicPages } from '../types';
import { dynamicPagesTags, DynamicSeoDataInput } from '../mocks';
import './title.css';

type TitleProps = TextareaField<any>['props'] & {
  name: DynamicSeoDataInput,
};

const props = defineProps({
  field: {
    type: Object as PropType<TitleProps>,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['blur', 'action', 'update:modelValue']);

const text = ref(props.modelValue);

const urlSegments = new URL(window.location.href).pathname.split('/');
const last = urlSegments.pop() as SupportedDynamicPages;
const tags = dynamicPagesTags?.[last] ? dynamicPagesTags[last][props.field.name] : [['city', 'Город']];

const addTag = (tagValue: string) => {
  text.value = `${text.value}{{${tagValue}}}`;
  emit('update:modelValue', text.value);
};

const updText = (newText: string) => {
  text.value = newText;
  emit('update:modelValue', text.value);
};

watchEffect(() => {
  text.value = props.modelValue;
});

const descriptionField: TextareaField<any> = {
  type: 'textarea',
  name: 'description',
  props: {
    placeholder: props.field.placeholder,
  },
};
</script>
