import { cardPageController } from 'magner';
import { saveImage } from 'features/common/file-request';
import { baseT } from 'configs/translation/base';
import type { Entity } from './types';
import { t } from './ru';
import {
  get, update, remove, create,
} from './requests';
import { EditorField } from '~/utils/editor';
import urlToTranslit from '~/utils/url-to-translit';
import Cascader from '~/components/cascader/cascader.vue';
import { categoryHierarchySearch } from './index';

export const cardConfig = cardPageController<Entity>({
  header: {
    title: t('pages.card_title'),
  },

  createRequest: create,
  getRequest: get,
  updateRequest: update,
  deleteRequest: remove,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: { name: 'categories' },
        props: {
          text: baseT('form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'id',
                  props: {
                    hidden: true,
                  },
                },
                {
                  type: 'input',
                  name: 'title',
                  label: t('form.name.label'),
                  props: {
                    required: true,
                    placeholder: t('form.name.placeholder'),
                  },
                  changeAction: ({ form, isNew }) => {
                    if (isNew) form.slug = urlToTranslit(form.title);
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                },
                {
                  type: 'input',
                  name: 'menuTitle',
                  label: t('form.menu_title.label'),
                  props: {
                    required: true,
                    placeholder: t('form.menu_title.placeholder'),
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                },
                {
                  type: 'input',
                  name: 'slug',
                  label: t('form.slug.label'),
                  props: {
                    required: true,
                    placeholder: t('form.slug.placeholder'),
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                },
                {
                  type: 'input',
                  name: 'sort',
                  label: t('form.sort.label'),
                  props: {
                    required: true,
                    placeholder: t('form.sort.placeholder'),
                  },
                  validation: [{
                    type: 'empty-required-number' as 'empty',
                    trigger: 'change',
                  }],
                },
                {
                  type: 'custom',
                  name: 'parent',
                  dataType: 'number',
                  component: () => Cascader,
                  label: t('form.parent.label'),
                  props: {
                    checkStrictly: true,
                    clearable: true,
                    value: 'id',
                    label: 'title',
                    children: 'categories',
                    remoteMethod: categoryHierarchySearch,
                    filterable: true,
                    initOnMount: true,
                  },
                },
                {
                  type: 'custom',
                  name: 'parents',
                  dataType: 'array',
                  component: () => Cascader,
                  label: t('form.parents.label'),
                  props: {
                    multiple: true,
                    checkStrictly: true,
                    value: 'id',
                    label: 'title',
                    children: 'categories',
                    remoteMethod: categoryHierarchySearch,
                    filterable: true,
                    initOnMount: true,
                  },
                },
                {
                  type: 'switch',
                  name: 'sizeFilterActive',
                  dataType: 'boolean',
                  label: t('form.size_filter_active.label'),
                  props: {
                    hidden: ({ isNew }) => isNew,
                  },
                },
                {
                  type: 'collection',
                  name: 'activeFilterSizes',
                  dataType: 'array',
                  props: { hidden: true },
                  layout: [
                    {
                      type: 'row',
                      props: {},
                      fields: [
                        {
                          type: 'input',
                          name: 'id',
                          props: {},
                        },
                        {
                          type: 'input',
                          name: 'slug',
                          props: {},
                        },
                        {
                          type: 'input',
                          name: 'title',
                          props: {},
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'dropzone',
                  name: 'image',
                  label: t('form.image.label'),
                  props: {
                    removable: true,
                    valueKey: 'id',
                    srcKey: 'downloadUrl',
                    saveToBackend: saveImage,
                    inputAtts: {
                      accept: 'image/png, image/jpeg, image/webp, image/svg+xml',
                    },
                  },
                },
              ],
            },
          ],
        },
        /** Right column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            {
              type: 'column',
              title: t('form.seo.label'),
              props: {
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'input',
                  name: 'h1',
                  label: t('form.h1.label'),
                  props: {
                    placeholder: t('form.h1.placeholder'),
                  },
                },
                {
                  type: 'textarea',
                  name: 'metaTitle',
                  label: t('form.meta_title.label'),
                  props: {
                    placeholder: t('form.meta_title.placeholder'),
                  },
                },
                {
                  type: 'input',
                  name: 'metaKeywords',
                  label: t('form.meta_keywords.label'),
                  props: {
                    placeholder: t('form.meta_keywords.placeholder'),
                  },
                },
                {
                  type: 'textarea',
                  name: 'metaDescription',
                  label: t('form.meta_description.label'),
                  props: {
                    placeholder: t('form.meta_description.placeholder'),
                  },
                },
              ],
            },
          ],
        },

        {
          type: 'column',
          title: t('form.content_top'),
          props: {
            isPaper: true,
            titleType: 'heading',
            class: 'editor-js',
          },
          fields: [
            {
              type: 'custom',
              name: 'detailTextTop',
              component: () => EditorField,
              props: {
                id: 'editor-category-detail-text-top',
              },
            },
          ],
        },
        {
          type: 'column',
          title: t('form.content_bottom'),
          props: {
            isPaper: true,
            titleType: 'heading',
            class: 'editor-js',
          },
          fields: [
            {
              type: 'custom',
              name: 'detailTextBottom',
              component: () => EditorField,
              props: {
                id: 'editor-category-detail-text-bottom',
              },
            },
          ],
        },
      ],
    },
  },
});
