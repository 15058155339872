import { request } from '~/utils/request';
import type { Entity } from './types';
import { getListResponse } from '~/utils/get-list';
import { filterRequestStrings } from '~/utils/filter-request-strings';

const BASE = '/api/admin/print-type';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  return getListResponse<Entity>(`${BASE}/list?${pagination}`, activePage);
});

export const get = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Entity }>(`${BASE}/read/${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  setTimeout(() => {
    const header = document.querySelector('.magner-page-header_title');
    const name = res.data?.data?.title;
    if (header && name) {
      header.innerHTML = `Вариант нанесения "${name}"`;
    }
  }, 4);

  const resData = {
    ...res.data?.data,
    h1: res.data?.data?.metaInfo?.h1 || '',
    metaTitle: res.data?.data?.metaInfo?.metaTitle || '',
    metaKeywords: res.data?.data?.metaInfo?.metaKeywords || '',
    metaDescription: res.data?.data?.metaInfo?.metaDescription || '',
  };

  return { data: resData };
});

export const create = request.card<Entity, Entity>(async ({ api, data, parseError, router}) => {
  filterRequestStrings(data.data);

  const body = {
    ...data.data,
    metaInfo: {}, // Должен быть пустым
  };

  if (data.data?.h1 || data.data?.h1 === null) body.metaInfo.h1 = data.data?.h1;
  if (data.data?.metaTitle || data.data?.metaTitle === null) body.metaInfo.metaTitle = data.data?.metaTitle;
  if (data.data?.metaKeywords || data.data?.metaKeywords === null) body.metaInfo.metaKeywords = data.data?.metaKeywords;
  if (data.data?.metaDescription || data.data?.metaDescription === null) body.metaInfo.metaDescription = data.data?.metaDescription;

  delete body.h1;
  delete body.metaTitle;
  delete body.metaKeywords;
  delete body.metaDescription;

  if (Array.isArray(body.image)) {
    body.image = body.image[0];
  }

  const res = await api.post<{ data: Entity }>(`${BASE}/create`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  router.push({ name: 'requirements' });
  return { data: res.data?.data };
});

export const update = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  filterRequestStrings(data.data);

  const body = {
    ...data.data,
    metaInfo: {}, // Должен быть пустым
  };

  if (data.data?.h1 || data.data?.h1 === null) body.metaInfo.h1 = data.data?.h1;
  if (data.data?.metaTitle || data.data?.metaTitle === null) body.metaInfo.metaTitle = data.data?.metaTitle;
  if (data.data?.metaKeywords || data.data?.metaKeywords === null) body.metaInfo.metaKeywords = data.data?.metaKeywords;
  if (data.data?.metaDescription || data.data?.metaDescription === null) body.metaInfo.metaDescription = data.data?.metaDescription;

  delete body.h1;
  delete body.metaTitle;
  delete body.metaKeywords;
  delete body.metaDescription;

  if (Array.isArray(body.image)) {
    body.image = body.image[0];
  } else {
    delete body.image;
  }

  const res = await api.patch<{ data: Entity }>(`${BASE}/update/${data.id}`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.data };
});

export const remove = request.custom<boolean, { ids: string[] }>(async ({ data, api, parseError }) => {
  const res = await api.post(`${BASE}/delete`, { ids: data.ids });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: true,
  };
});
