import { validationController } from 'magner';

/** Validations are functions that are used for form fields validation.
 * Here, you are configuring the object where key is a validation unique name and value is
 * a checking function.<br>
 *
 * First argument is a data you can use to check field value<br>
 * Second argument is callback. If callback argument is 'new Error("message")', the error will be shown over the field.
 */
const validation = validationController({
  'empty-title': ({ value, form }, callback) => {
    if (value.trim() && !value.includes('{{title}}')) {
      callback(new Error('Переменная {{title}} обязательная к заполнению'));
    }
    callback();
  },

  'empty-required': ({ value, form }, callback) => {
    if (typeof value === 'undefined'
      || value === null
      || (typeof value === 'string' && !value?.trim().length)
      || (Array.isArray(value) && !value.length)
    ) {
      callback(new Error('Поле обязательно к заполнению'));
    }
    callback();
  },

  'empty-required-number': ({ value, form }, callback) => {
    if (
      typeof value === 'undefined'
      || value === null
      || (typeof value === 'string' && !value?.trim().length)
      || (Array.isArray(value) && !value.length)
    ) {
      callback(new Error('Поле обязательно к заполнению'));
    }

    if (!/^\d+$/.test(value)) {
      callback(new Error('Введите целое число'));
    }

    if (`${value}`.length > 9) {
      callback(new Error('Слишком большое число'));
    }

    callback();
  },

  'empty-required-price': ({ value, form }, callback) => {
    if (
      typeof value === 'undefined'
      || value === null
      || (typeof value === 'string' && !value?.trim().length)
      || (Array.isArray(value) && !value.length)
    ) {
      callback(new Error('Поле обязательно к заполнению'));
    }

    if (!/^\d+(\.)?(\d+)?$/.test(value)) {
      callback(new Error('Введите корректное значение'));
    }

    if (`${+Math.floor(value)}`.length > 7) {
      callback(new Error('Слишком большая цена'));
    }

    callback();
  },

  'email-required': ({ rule, value }, callback) => {
    if (value && !value.match(/[^@]+@[^.]+\..+/i)) {
      callback(new Error('Неверный формат E-mail'));
    } else {
      callback();
    }
  },

  'user-roles': ({ rule, value }, callback) => {
    if (value.includes('ROLE_USER') && value.length > 1) {
      callback(new Error('Роли "Пользователь" нельзя добавлять другие роли'));
    } else {
      callback();
    }
  },

  url: ({ rule, value }, callback) => {
    if (value && !value.match(/(https?:\/\/)[a-zа-яё0-9._-]+\.[a-zа-яё]+(\/?.*)?/i)) {
      callback(new Error('Неверный формат ссылки'));
    } else {
      callback();
    }
  },

  /** Example validator showing that you can access values from other fields of the form */
  passwordConfirm: ({ value, form }, callback) => {
    if (value && form.password !== value) {
      callback(new Error('Пароли не совпадают!'));
    } else if (!value) {
      callback(new Error('Введите повтор пароля'));
    } else {
      callback();
    }
  },

  /** Phone field validation */
  phone: ({ rule, value, form }, callback) => {
    if (value && !value.startsWith('+')) {
      callback(new Error('Номер должен начинаться с "+"'));
    } else {
      callback();
    }
  },

  'max-length-128': ({ value }, callback) => {
    if (value.trim().length > 128) {
      callback(new Error('Максимальное количество символов 128'));
    } else {
      callback();
    }
  },
});

export default validation;
