import { ft } from '~/utils/feature-translate';

export const ru = {
  sidebar: {
    table_title: 'Экспорты',
  },
  table: {
    title: 'Экспорты',
    not_found: 'Экспорты не найдены',

    supplier: 'Поставщик',
    download_action: 'Скачать',
    updated_at: 'Время и дата формирования',
    size: 'Размер файла',
  },
};

export const t = ft(ru, 'exports.');
