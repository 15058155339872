import { API as EditorApi } from '@editorjs/editorjs';
import { saveImages } from 'features/common/file-request';
import type { ImageBlockConfig } from './blocks/images/types';

export const setupEditor = async (data: {
  holder: string,
  placeholder: string,
  autofocus?: boolean,
  data?: any,
  readOnly?: boolean,
  onReady?: () => void,
  onChange: (editor: EditorApi) => void,
}) => {
  if (typeof window === 'undefined') return null;

  const EditorJS = (await import('@editorjs/editorjs')).default;
  const List = (await import('@editorjs/list')).default;
  const Raw = (await import('@editorjs/raw')).default;
  const Table = (await import('editorjs-table-readonly')).default;

  // @ts-ignore
  const Images = (await import('./blocks/images/index')).default;
  // @ts-ignore
  const Header = (await import('./blocks/header')).default;
  // @ts-ignore
  const Alert = (await import('./blocks/alert')).default;
  // @ts-ignore
  const Delimiter = (await import('./blocks/delimiter/index')).default;
  // @ts-ignore
  const Products = (await import('./blocks/products/index')).default;
  // @ts-ignore
  const YoutubeEmbed = (await import('./blocks/youtube/index')).default;

  // @ts-ignore
  return new EditorJS({
    ...data,
    tools: {
      header: Header,
      list: {
        class: List,
        inlineToolbar: true,
      },
      images: {
        // @ts-ignore
        class: Images,
        inlineToolbar: true,
        config: {
          uploadFiles: saveImages,
          multiple: true,
          buttonContent: 'Выбрать изображения',
          accept: 'image/png, image/jpeg, image/webp',
        } as ImageBlockConfig,
      },
      table: Table,
      alert: Alert,
      youtubeEmbed: YoutubeEmbed,
      raw: Raw,
      delimiter: Delimiter,
      products: Products,
    },
  });
};
