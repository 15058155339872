import {
  tablePageController,
  filtersFormController,
  tableController,
} from 'magner';
import { read } from './requests';
import { t } from './ru';
import type { Entity } from './types';

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('pages.table_title'),
  },

  request: read,

  table: tableController<Entity>({
    emptyText: t('table.not_found'),
    columns: [
      {
        prop: 'name',
        label: t('table.name'),
        sortable: true,
      },
    ],
  }),

  filters: filtersFormController({
    saveToLocalStorage: true,

    actions: [],

    submitEvent: 'input',

    pagination: {
      items: 100,
      page: 1,
    },

    filtersData: {
      name: '',
    },

    sort: {},

    layout: [
      {
        type: 'input',
        name: 'name',
        props: {
          placeholder: t('table.search'),
          inputDelay: 400,
        },
      },
    ],
  }),
});
