import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import Description from 'features/seo/components/description.vue';
import Title from 'features/seo/components/title.vue';
import type { Entity } from '../types';
import { t } from '../ru';
import { get, update } from '../requests';

export const cardConfig = cardPageController<Entity>({
  header: {
    title: t('form.header'),
  },

  getRequest: get,
  createRequest: update,
  updateRequest: update,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: { name: 'seo-static' },
        props: {
          text: baseT('form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'custom',
          name: 'title',
          dataType: 'string',
          component: () => Title,
          label: t('form.title.label'),
          props: {
            required: true,
            placeholder: 'Введите название страницы',
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },
        {
          type: 'custom',
          name: 'h1',
          dataType: 'string',
          component: () => Title,
          label: t('form.h1.label'),
          props: {
            placeholder: 'Введите заголовок',
          },
        },
        {
          type: 'custom',
          name: 'h2',
          dataType: 'string',
          component: () => Title,
          label: t('form.h2.label'),
          props: {
            placeholder: 'Введите подзаголовок',
          },
        },
        {
          type: 'custom',
          name: 'keywords',
          dataType: 'string',
          component: () => Title,
          label: t('form.keywords.label'),
          props: {
            placeholder: 'Введите ключевые слова',
          },
        },
        {
          type: 'custom',
          name: 'description',
          dataType: 'string',
          component: () => Description,
          label: t('form.description.label'),
          props: {
            required: true,
            placeholder: 'Введите описание',
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },
      ],
    },
  },
});
