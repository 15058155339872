import { cardPageController } from 'magner';
import { saveImage } from 'features/common/file-request';
import { baseT } from 'configs/translation/base';
import { EditorField } from '~/utils/editor';
import type { Entity } from './types';
import { t } from './ru';
import {
  get, update, remove, create,
} from './requests';
import urlToTranslit from '~/utils/url-to-translit';

export const cardConfig = cardPageController<Entity>({
  header: {
    title: t('pages.card_title'),
  },

  createRequest: create,
  getRequest: get,
  updateRequest: update,
  deleteRequest: remove,

  form: {
    actions: [
      {
        type: 'link',
        to: { name: 'news' },
        props: {
          text: baseT('form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          fields: [
            {
              type: 'input',
              name: 'title',
              label: t('form.name.label'),
              props: {
                required: true,
                placeholder: t('form.name.placeholder'),
              },
              changeAction: ({ form, isNew }) => {
                if (isNew) form.slug = urlToTranslit(form.title);
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
            {
              type: 'input',
              name: 'slug',
              label: t('form.slug.label'),
              props: {
                required: true,
                placeholder: t('form.slug.placeholder'),
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
            {
              type: 'textarea',
              name: 'previewText',
              label: t('form.description.label'),
              props: {
                required: true,
                placeholder: t('form.description.placeholder'),
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
            {
              type: 'datetime',
              name: 'publishedAt',
              dataType: 'date',
              label: t('form.publishedAt.label'),
              props: {
                required: true,
                placeholder: t('form.publishedAt.placeholder'),
                format: 'DD.MM.YYYY',
                type: 'date',
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
            {
              type: 'dropzone',
              name: 'previewImage',
              label: t('form.cover.label'),
              props: {
                removable: true,
                required: true,
                valueKey: 'id',
                srcKey: 'downloadUrl',
                saveToBackend: saveImage,
                inputAtts: {
                  accept: 'image/png, image/jpeg, image/webp',
                },
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: { span: 12, xs: 24, styles: { 'padding-left': '12px' } },
          layout: [
            {
              type: 'column',
              title: t('form.seo.label'),
              props: {
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'input',
                  name: 'h1',
                  label: t('form.h1.label'),
                  props: {
                    placeholder: t('form.h1.placeholder'),
                  },
                },
                {
                  type: 'textarea',
                  name: 'metaTitle',
                  label: t('form.meta_title.label'),
                  props: {
                    placeholder: t('form.meta_title.placeholder'),
                  },
                },
                {
                  type: 'input',
                  name: 'metaKeywords',
                  label: t('form.meta_keywords.label'),
                  props: {
                    placeholder: t('form.meta_keywords.placeholder'),
                  },
                },
                {
                  type: 'textarea',
                  name: 'metaDescription',
                  label: t('form.meta_description.label'),
                  props: {
                    placeholder: t('form.meta_description.placeholder'),
                  },
                },
              ],
            },
            {
              type: 'row',
              props: { elementsGrow: true },
              fields: [
                {
                  type: 'switch',
                  name: 'active',
                  dataType: 'boolean',
                  label: t('form.active'),
                  props: {},
                },
              ],
            },
          ],
        },

        /** EditorJS under the column */
        {
          type: 'row',
          title: t('form.content'),
          props: {
            isPaper: true,
            titleType: 'heading',
            elementsGrow: true,
            class: 'editor-js',
          },
          fields: [
            {
              type: 'custom',
              name: 'detailText',
              component: () => EditorField,
              props: {
                required: true,
                id: 'editor-news',
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
          ],
        },
      ],
    },
  },
});
