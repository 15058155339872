import { translate } from 'magner';

// For convenience
type Primitive = string | number | bigint | boolean | undefined | symbol;

// To infinity and beyond >:D
export type PropertyStringPath<T, Prefix=''> = {
  [K in keyof T]: T[K] extends Primitive | Array<any>
    ? `${string & Prefix}${string & K}`
    : `${string & Prefix}${string & K}` | PropertyStringPath <T[K], `${string & Prefix}${string & K}.`> ;
}[keyof T];

export const ft = <TranslationObject extends object>(obj: TranslationObject, prefix = '') => (
  path: PropertyStringPath<TranslationObject>,
  // @ts-ignore
) => translate(`${prefix}${path as unknown as string}`);
