import { baseRu } from 'configs/translation/base';
import { loginRu } from 'features/login/ru';

// Users
import { userRu } from 'features/users/users';
import { legalPersonRu } from 'features/users/legal-persons';

// Orders
import { orderRu } from 'features/orders';

// Content
import { promotionsRu } from 'features/content/promotions';
import { newsRu } from 'features/content/news';
import { blogRu } from 'features/content/blog';
import { contactsRu } from 'features/contacts';
import { contentPagesRu } from 'features/content/pages';
import { requirementsRu } from 'features/content/requirements';
import { orderInfoRu } from 'features/content/order-info';

// Catalog
import { categoryRu } from 'features/catalog/categories';
import { brandRu } from 'features/catalog/brands';
import { productsRu } from 'features/catalog/products';
import { variationsRu } from 'features/catalog/products/variations';

// Catalog imports
import { brandImportRu } from 'features/catalog-import/brands';

// Settings
import { seoRu } from 'features/seo';
import { bannerRu } from 'features/settings/banners';
import { sliderRu } from 'features/settings/slider';
import { minPriceOrderRu } from 'features/settings/min-price-order';
import { footerCategoriesRu } from 'features/settings/footer-categories';
import { settingsImagesRu } from 'features/settings/images';
import { exportsRu } from 'features/exports';
import { categoryImportRu } from 'features/catalog-import/categories';
import { productsImportRu } from 'features/catalog-import/products';
import { pricesRu } from 'features/settings/prices';

// Mappings
import { baseColorsRu } from 'features/mappings/base-colors';
import { propertiesRu } from 'features/mappings/properties';
import { baseMaterialsRu } from 'features/mappings/base-materials';

import { productsEditorModalRu } from '~/utils/editor/blocks/products/products-modal';

const ruCustom = {
  base: baseRu,
  login: loginRu,
  user: userRu,

  users: {
    user: userRu,
    legal: legalPersonRu,
  },

  orders: orderRu,

  content: {
    news: newsRu,
    blog: blogRu,
    pages: contentPagesRu,
    requirements: requirementsRu,
    order_info: orderInfoRu,
    promotions: promotionsRu,
  },

  contacts: contactsRu,

  catalog: {
    categories: categoryRu,
    brands: brandRu,
    products: productsRu,
    variations: variationsRu,
    editor_modal: productsEditorModalRu,
  },

  catalog_imports: {
    brands: brandImportRu,
    categories: categoryImportRu,
    products: productsImportRu,
  },

  settings: {
    banner: bannerRu,
    seo: seoRu,
    slider: sliderRu,
    footer_categories: footerCategoriesRu,
    images: settingsImagesRu,
    min_price_order: minPriceOrderRu,
    prices: pricesRu,
  },

  exports: exportsRu,

  mappings: {
    base_colors: baseColorsRu,
    base_materials: baseMaterialsRu,
    properties: propertiesRu,
  },
};

export type CustomTranslationSchema = typeof ruCustom;
export default ruCustom;
