import { ft } from '~/utils/feature-translate';

export const ru = {
  sidebar: {
    table_title: 'Вариации товара',
    card_title: 'Вариация',
  },
  table: {
    not_found: 'Юридические лица не найдены',
    remove_many: 'Деактивировать выбранное',
    active_many: 'Активировать выбранное',

    size: 'Размер',
    sku: 'Артикул',
    old_price: 'Цена',
    price_with_discount: 'Цена со скидкой',
    active: 'Активна',
  },
  filters: {},
  form: {
    tabs: {
      product: 'Товар',
      variations: 'Вариации',
    },

    stock: {
      title: 'Количество остатков',

      warehouse: {
        label: 'Склад',
        placeholder: 'Выбрать',
      },

      count: {
        label: 'Количество',
        placeholder: '100',
      },
    },

    multi_prices: {
      title: 'Цена от количества',

      quantity_from: {
        label: 'От',
        placeholder: '1',
      },

      quantity_to: {
        label: 'До',
        placeholder: '100',
      },

      price: {
        label: 'Цена за ед; ₽',
        placeholder: '1500',
      },
    },

    size: { label: 'Размер', placeholder: 'Xs' },
    sku: { label: 'Артикул', placeholder: '' },
    price: { label: 'Цена', placeholder: '1200' },
  },
};

export const t = ft(ru, 'catalog.variations.');
