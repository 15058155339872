import type {
  Entity,
  SupportedStaticPages,
  SupportedDynamicPages,
} from './types';
import { ru } from './ru';

export const fakeData: Entity = {
  slug: 'index',
  title: '',
  description: '',
  keywords: '',
  h1: '',
  h2: '',
};

export const seoStaticPages: SupportedStaticPages[] = [
  'index', // главная
  // контентные
  'delivery',
  'payment',
  'obmen-vozvrat-tovarov',
  'privacy',
  'public_offer',
  'agreement',
  'requirements',
  'about',
  'vacancy',
  // служебные
  'error',
  'comparison',
  'favorites',
  'contacts',
  'news',
  'blog',
  'promotions',
  'print',
  'catalog',
  'brands',
  'newones',
  'recommended',
  'bestoffers',
  'cart',
  'profile',
  'companies',
  'orders',
  'ordering',
  'profile-kp',
  'kp',
  'search',
  'faq',
];

export const seoDynamicPages: SupportedDynamicPages[] = [
  'product',
  'print-post',
  'category',
  'brand',
  'blog-post',
  'news-post',
  'promotion-post',
];

export type DynamicSeoDataInput = 'title' | 'keywords' | 'description';
type DynamicSeoData = 'title' | 'keywords' | 'description' | 'discount' | 'minPrice' | 'sku' | 'city' | 'properties'

export const dynamicPagesTags: Record<SupportedDynamicPages, Record<DynamicSeoDataInput, [DynamicSeoData, string][]>> = {
  'blog-post': {
    title: [['title', 'Название'], ['city', 'Город']],
    keywords: [['city', 'Город']],
    description: [['description', 'Описание'], ['city', 'Город']],
  },
  'news-post': {
    title: [['title', 'Название'], ['city', 'Город']],
    keywords: [['city', 'Город']],
    description: [['description', 'Описание'], ['city', 'Город']],
  },
  'print-post': {
    title: [['title', 'Название'], ['city', 'Город']],
    keywords: [['city', 'Город']],
    description: [['description', 'Описание'], ['city', 'Город']],
  },
  'promotion-post': {
    title: [['title', 'Название'], ['city', 'Город']],
    keywords: [['city', 'Город']],
    description: [['description', 'Описание'], ['city', 'Город']],
  },
  brand: {
    title: [['title', 'Название'], ['city', 'Город']],
    keywords: [['city', 'Город']],
    description: [['city', 'Город']],
  },
  category: {
    title: [['title', 'Название'], ['city', 'Город']],
    keywords: [['city', 'Город']],
    description: [['city', 'Город']],
  },
  product: {
    title: [['title', 'Название'], ['discount', 'Скидка'], ['minPrice', 'Минимальная цена'], ['sku', 'Артикул'], ['city', 'Город'], ['properties', 'Свойства']],
    keywords: [['description', 'Описание'], ['discount', 'Скидка'], ['minPrice', 'Минимальная цена'], ['sku', 'Артикул'], ['city', 'Город'], ['properties', 'Свойства']],
    description: [['description', 'Описание'], ['discount', 'Скидка'], ['minPrice', 'Минимальная цена'], ['sku', 'Артикул'], ['city', 'Город'], ['properties', 'Свойства']],
  },
};

export const getTableData = (
  pages: SupportedStaticPages[] | SupportedDynamicPages[],
): Entity[] => pages.map((page) => ({
  slug: page,
  title: ru.sidebar.title[page],
  description: '',
  keywords: '',
})) as Entity[];
