import { actionTable, cardPageController } from 'magner';
import { saveImage } from 'features/common/file-request';
import { baseT } from 'configs/translation/base';
import type { Entity } from './types';
import { get, update } from './requests';
import { t } from './ru';
import ImageLoad from './image-load.vue';

export const cardConfig = cardPageController<Entity>({
  header: {
    title: t('form.title'),
  },

  getRequest: get,
  updateRequest: update,
  createRequest: update,

  form: {
    actions: [
      {
        type: 'link',
        to: { name: 'banners' },
        props: {
          text: baseT('form.cancel_button'),
        },
      },
    ],

    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'dropzone',
          name: 'image',
          label: t('form.image.label'),
          hint: t('form.image.hint'),
          props: {
            multiple: false,
            inputAtts: {
              accept: 'image/png, image/jpeg, image/webp',
            },
          },
        },
        {
          type: 'custom',
          name: 'loaded',
          component: () => ImageLoad,
          props: {},
        },
      ],
    },
  },
});
