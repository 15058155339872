import { ft } from '~/utils/feature-translate';

export const ru = {
  sidebar: {
    table_title: 'Слайдер на главной',
  },
  table: {
    title: 'Слайды на главной',
    remove_many: 'Деактивировать выбранное',
    active_many: 'Активировать выбранное',

    description: 'Описание',
    not_found: 'Слайды не найдены',
    slider_title: 'Заголовок',
    link: 'Ссылка',
    link_text: 'Описание ссылки',
    image: 'Изображение',
    active: 'Активен',
    sort: 'Сортировка',
  },
  form: {
    title: 'Редактирование слайда',
    name: { label: 'Заголовок', placeholder: 'Слайд 1' },
    description: { label: 'Короткое описание', placeholder: 'Описание слайда' },
    link_text: { label: 'Текст ссылки', placeholder: 'Вперед' },
    link: { label: 'Ссылка', placeholder: '/category/some-category' },
    image: { label: 'Баннер (рекомендуемый размер 1280x300)' },
    mobile_image: { label: 'Баннер для мобильной версии (рекомендуемый размер 768x480)' },
    sort: { label: 'Сортировка' },
  },
};

export const t = ft(ru, 'settings.slider.');
