import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Свойства',
    table_title: 'Свойства',
  },
  table: {
    create_new: 'Создать',
    not_found: 'Свойства не найдены',
    remove_many: 'Удалить выбранные',
    mapping_modal_button: 'Создать сопоставления',

    title: 'Название',
    count: 'Количество совпадений в базе',

    search: 'Поиск',
  },
};

export const t = ft(ru, 'mappings.properties.');
