import { request } from '~/utils/request';
import type { Entity } from './types';

const BASE = '/api/admin/import-goods/brand';

export const read = request.table<Entity>(async ({ data }) => {
  const search = data.filters.name ? `?filters[0][id]=search&filters[0][value]=${data.filters.name}` : '';

  const res = await request.api.get<{ data: { items: Entity[] } }>(`${BASE}/list-not-mapped${search}`);

  setTimeout(() => {
    const paginationEl = document.querySelector('.table-page_pagination');
    if (paginationEl) {
      paginationEl.remove();
    }
  }, 4);

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data?.data?.items,
      pagination: {
        currentPage: 1,
        totalPages: 1,
        totalItems: res.data?.data?.items?.length,
      },
    },
  };
});
