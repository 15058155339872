import { request } from '~/utils/request';
import type { Entity } from './types';
import { FilterList } from '~/types';

// Только поставщики у которых есть элементы
export const searchOnlyHasItems = request.custom<{ title: string, id: number }[]>(async (data) => {
  const localSearch = data.data?.search || '';
  const searchQuery = data?.data?.form?.title ? `search=${data?.data?.form?.title}` : '';
  const brandQuery = data?.data?.form?.brand ? `brand=${data?.data?.form?.brand}` : '';
  const categoryQuery = data?.data?.form?.category ? `category=${data?.data?.form?.category}` : '';
  const activeQuery = data?.data?.form.active ? 'activeOnly=1' : '';

  const queries: string[] = [searchQuery, brandQuery, categoryQuery, activeQuery].filter((item) => !!item);

  const res = await request.api.get(`/api/admin/filter/list?${queries.join('&')}`);

  if (res?.data?.data?.items) {
    const items: FilterList = res.data.data.items;

    const suppliers = items.suppliers.filter((item) => (localSearch
      ? item.title.toLowerCase().includes(localSearch.toLowerCase())
      : item
    ));

    return { data: suppliers.length ? suppliers : [{ id: 1, title: 'Ничего не найдено', disabled: true }] };
  }

  return { data: [{ id: 1, title: 'Ошибка на сервере', disabled: true }] };
});

export const searchAll = request.custom<Entity[]>(async (data) => {
  const searchQuery = data.data?.search ? `search=${data.data?.search}` : '';

  const res = await request.api.get(`/api/admin/filter/all?${searchQuery}`);

  if (res?.data?.data?.items) {
    const items: FilterList = res.data.data.items;

    return { data: items.suppliers as Entity[] };
  }

  return { data: [] };
});
