import { cardPageController, type InputField } from 'magner';
import { baseT } from 'configs/translation/base';
import type { City, Entity } from './types';
import { t, formT } from './ru';
import { get, update } from './requests';

const getContactsField = (name: keyof Entity, required = false): InputField<Entity> => ({
  type: 'input',
  name,
  label: formT(name, 'label'),
  props: {
    required,
    placeholder: formT(name, 'placeholder'),
  },
  validation: required ? [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }] : [],
});

export const cardConfig = (item: City) => cardPageController<Entity>({
  header: {
    title: t(`pages.${item.title}`),
  },

  getRequest: get(item.id),
  createRequest: update(item.id),
  updateRequest: update(item.id),

  form: {
    fullDataOnUpdate: true,

    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          fields: [
            getContactsField('phone'),
            getContactsField('general.email'),
            getContactsField('heads.email'),
            getContactsField('operation.mode'),
            getContactsField('legal.address'),

            getContactsField('office.postalCode'),
            getContactsField('office.cityAddress'),
            getContactsField('office.streetAddress'),

            getContactsField('coordinates'),
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: { span: 12, xs: 24, styles: { 'padding-left': '12px' } },
          fields: [
            getContactsField('receiver.bank'),
            getContactsField('inn'),
            getContactsField('kpp'),
            getContactsField('ogrn'),
            getContactsField('bik'),
            getContactsField('ks'),
            getContactsField('rs'),
          ],
        },
      ],
    },
  },
});
