import { cardPageController } from 'magner';
import { saveImage } from 'features/common/file-request';
import { baseT } from 'configs/translation/base';
import type { Entity } from './types';
import { get, update } from './requests';
import { t } from './ru';

export const cardConfig = cardPageController<Entity>({
  header: {
    title: t('form.title'),
  },

  getRequest: get,
  updateRequest: update,
  createRequest: update,

  form: {
    actions: [
      {
        type: 'link',
        to: { name: 'banners' },
        props: {
          text: baseT('form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'input',
          name: 'title',
          label: t('form.name.label'),
          props: {
            placeholder: t('form.name.placeholder'),
          },
        },

        {
          type: 'input',
          name: 'link',
          label: t('form.link.label'),
          props: {
            required: true,
            placeholder: t('form.link.placeholder'),
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },

        {
          type: 'textarea',
          name: 'description',
          label: t('form.description.label'),
          props: {
            placeholder: t('form.description.placeholder'),
          },
        },

        {
          type: 'dropzone',
          name: 'desktopImage',
          label: t('form.desktop.label'),
          props: {
            required: true,
            valueKey: 'id',
            srcKey: 'downloadUrl',
            saveToBackend: saveImage,
            inputAtts: {
              accept: 'image/png, image/jpeg, image/webp',
            },
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },

        {
          type: 'dropzone',
          name: 'mobileImage',
          label: t('form.mobile.label'),
          props: {
            required: true,
            valueKey: 'id',
            srcKey: 'downloadUrl',
            saveToBackend: saveImage,
            inputAtts: {
              accept: 'image/png, image/jpeg, image/webp',
            },
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },
      ],
    },
  },
});
