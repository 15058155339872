import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Категории',
    table_title: 'Категории',
  },
  table: {
    not_found: 'Категории не найдены',
    name: 'Категория',
    supplier: 'Поставщик',
    to_products: 'Ссылка на товары',
    to_products_action_btn: 'Перейти',
    categories: 'Рубрика основного каталога',
  },
};

export const t = ft(ru, 'catalog_imports.categories.');
