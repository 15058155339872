import { NullOrString } from '~/types';

// eslint-disable-next-line no-shadow
export enum USER_ROLES {
  Admin = 'ROLE_ADMIN',
  User = 'ROLE_USER',
  Manager = 'ROLE_MANAGER',
  SuperManager = 'ROLE_SENIOR_MANAGER',
  SalesChief = 'ROLE_SALES_CHIEF',
  AdminManager = 'ROLE_ADMIN_MANAGER',
}

export interface User {
  id: string,
  name: string,
  login: string,
  phone: NullOrString,
  additionalPhone?: null | number,
  roles: USER_ROLES[],
  birthdate: string,
  activated: boolean,
  isPublicManager: boolean,
}

interface Address {
  id: string,
  address: string,
}

export interface UserResponse extends User {
  login: string,
  password: string,
  changePasswordAt: null | Date,
  createdAt: Date,
  shipmentAddresses: Address[],
  city: null,
  avatar: NullOrString,
}

export interface UserList extends User {
  email: string,
  avatar: NullOrString,
  addresses: string[],
  companies: string[],
  deleted: boolean,
  created_at: {
    date: string,
    timezone: string,
    timezone_type: number,
  },
}

export interface UserCreate extends Omit<User, 'birthdate'> {
  email: string,
  avatar?: NullOrString,
  shipmentAddresses: string[],
  legals: string[],
  city: string,
  password?: string,
  birthdate?: Date | null | string,
}
