import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import type { Entity } from './types';
import { FilterList } from '~/types';

const BASE = '/api/admin/brand';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;
  const search = data.filters.title ? `&filters[0][id]=search&filters[0][value]=${data.filters.title}` : '';

  return getListResponse<Entity>(`${BASE}/list?${pagination}${search}`, activePage);
});

export const searchOnlyHasItems = request.custom<{ title: string, id: number }[]>(async (data) => {
  const localSearch = data.data?.search || '';
  const searchQuery = data?.data?.form.title ? `search=${data?.data?.form?.title}` : '';
  const supplierQuery = data?.data?.form.supplier ? `supplier=${data?.data?.form?.supplier}` : '';
  const categoryQuery = data?.data?.form.category ? `category=${data?.data?.form?.category}` : '';
  const activeQuery = data?.data?.form.active ? 'activeOnly=1' : '';

  const queries: string[] = [searchQuery, supplierQuery, categoryQuery, activeQuery].filter((item) => !!item);

  const res = await request.api.get(`/api/admin/filter/list?${queries.join('&')}`);

  if (res?.data?.data?.items) {
    const items: FilterList = res.data.data.items;

    const brands = items.brands.filter((item) => (localSearch
      ? item.title.toLowerCase().includes(localSearch.toLowerCase())
      : item
    ));

    return { data: brands.length ? brands : [{ id: 1, title: 'Ничего не найдено', disabled: true }] };
  }

  return { data: [{ id: 1, title: 'Ошибка на сервере', disabled: true }] };
});

export const searchAll = request.custom<Entity[]>(async (data) => {
  const searchQuery = data.data?.search ? `search=${data.data?.search}` : '';

  const res = await request.api.get(`/api/admin/filter/all?${searchQuery}`);

  if (res?.data?.data?.items) {
    const items: FilterList = res.data.data.items;

    return { data: items.brands as Entity[] };
  }

  return { data: [] };
});

export const get = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Entity }>(`${BASE}/read/${data.id}`, data.data);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  const resData = {
    ...res.data?.data,
    image: res.data?.data?.imagePath || null,
    h1: res.data?.data?.metaInfo?.h1 || '',
    metaTitle: res.data?.data?.metaInfo?.metaTitle || '',
    metaKeywords: res.data?.data?.metaInfo?.metaKeywords || '',
    metaDescription: res.data?.data?.metaInfo?.metaDescription || '',
    detailTextTop: res.data?.data?.metaInfo?.detailTextTop || '',
    detailTextBottom: res.data?.data?.metaInfo?.detailTextBottom || '',
  };

  delete resData.metaInfo;
  return { data: resData };
});

export const create = request.card<Entity, Entity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  console.log('create'); // не убирать
  filterRequestStrings(data.data);

  const body = {
    ...data.data,
    metaInfo: {}, // Должен быть пустым
  };

  if (data.data?.h1 || data.data?.h1 === null) body.metaInfo.h1 = data.data?.h1;
  if (data.data?.metaTitle || data.data?.metaTitle === null) body.metaInfo.metaTitle = data.data?.metaTitle;
  if (data.data?.metaKeywords || data.data?.metaKeywords === null) body.metaInfo.metaKeywords = data.data?.metaKeywords;
  if (data.data?.metaDescription || data.data?.metaDescription === null) body.metaInfo.metaDescription = data.data?.metaDescription;
  if (data.data?.detailTextTop || data.data?.detailTextTop === null) body.metaInfo.detailTextTop = data.data?.detailTextTop;
  if (data.data?.detailTextBottom || data.data?.detailTextBottom === null) body.metaInfo.detailTextBottom = data.data?.detailTextBottom;

  delete body.h1;
  delete body.metaTitle;
  delete body.metaKeywords;
  delete body.metaDescription;
  delete body.detailTextTop;
  delete body.detailTextBottom;

  if (Array.isArray(body.image)) {
    body.image = body.image[0];
  }

  const res = await api.post<{ data: Entity }>(`${BASE}/create`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  router.push({ name: 'brands' });
  return { data: res.data?.data };
});

export const update = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  filterRequestStrings(data.data);

  const body = {
    ...data.data,
    metaInfo: {}, // Должен быть пустым
  };

  if (data.data?.h1 || data.data?.h1 === null) body.metaInfo.h1 = data.data?.h1;
  if (data.data?.metaTitle || data.data?.metaTitle === null) body.metaInfo.metaTitle = data.data?.metaTitle;
  if (data.data?.metaKeywords || data.data?.metaKeywords === null) body.metaInfo.metaKeywords = data.data?.metaKeywords;
  if (data.data?.metaDescription || data.data?.metaDescription === null) body.metaInfo.metaDescription = data.data?.metaDescription;
  if (data.data?.detailTextTop || data.data?.detailTextTop === null) body.metaInfo.detailTextTop = data.data?.detailTextTop;
  if (data.data?.detailTextBottom || data.data?.detailTextBottom === null) body.metaInfo.detailTextBottom = data.data?.detailTextBottom;

  delete body.h1;
  delete body.metaTitle;
  delete body.metaKeywords;
  delete body.metaDescription;
  delete body.detailTextTop;
  delete body.detailTextBottom;

  if (Array.isArray(body.image)) {
    if (body.image.length) {
      body.image = body.image[0];
    } else {
      body.image = null;
    }
  } else if (typeof body.image === 'string') {
    delete body.image;
  }

  const res = await api.patch<{ data: Entity }>(`${BASE}/update/${data.id}`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.data };
});

export const batch = request.custom<boolean, { ids: string[] }>(async ({ data, api, parseError }) => {
  const res = await api.post(`${BASE}/delete`, { ids: data.ids });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: true,
  };
});

export const remove = request.card<boolean, Entity>(async ({ data }) => batch({
  ids: [data.id as string],
}));
