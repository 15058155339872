import { USER_ROLES } from 'features/users/users';
import { City } from 'features/contacts/types';
import { t } from './ru';

import { cardConfig as contactsCardConfig } from './card';

export { ru as contactsRu } from './ru';

// TODO по-хорошему нужно получать данные с бэка
const DEFAULT_CITIES: City[] = [
  { id: 1, title: 'spb' },
  { id: 2, title: 'msk' },
  { id: 3, title: 'ru' },
  { id: 4, title: 'other' },
];

const getContactPageRoute = (city: City) => ({
  type: 'preset' as 'preset',
  preset: {
    preset: 'card' as 'card',
    config: contactsCardConfig(city),
  },
  route: {
    name: `contact-${city.title}`,
    path: `/contacts/${city.title}`,
    roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
    visible: true,
    title: t(`sidebar.title.${city.title}`),
  },
});

export const contactsPageRoutes = {
  sidebarGroup: DEFAULT_CITIES.map((item) => `contact-${item.title}`),
  routes: DEFAULT_CITIES.map((item) => getContactPageRoute(item)),
};
