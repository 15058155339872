import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import {
  get, create, update, remove,
} from './requests';
import type { LegalPerson } from './types';
import { t } from './ru';
import { saveImage } from 'features/common/file-request';

export const cardConfig = cardPageController<LegalPerson>({
  header: {
    title: t('form.title'),
  },

  getRequest: get,
  createRequest: create,
  updateRequest: update,
  deleteRequest: remove,

  confirmDelete: t('form.confirm_delete'),

  form: {
    actions: [
      {
        type: 'link',
        to: (route) => ({ name: 'user-companies', params: { id: route.query.user } }),
        props: {
          text: baseT('form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
      // {
      //   type: 'action',
      //   emits: 'remove',
      //   props: {
      //     text: baseT('form.remove_button'),
      //     type: 'danger',
      //   },
      // },
    ],

    layout: {
      type: 'column',
      props: {},
      layout: [
        /** TITLE */
        {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'input',
              name: 'shortTitle',
              label: t('form.short_title.label'),
              props: {
                required: true,
                placeholder: t('form.short_title.placeholder'),
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
          ],
        },
        /** ADDRESS */
        {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'input',
              name: 'address',
              label: t('form.address.label'),
              props: {
                placeholder: t('form.address.placeholder'),
              },
            },
          ],
        },
        /** BANK */
        {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'input',
              name: 'bankTitle',
              label: t('form.bank.label'),
              props: {
                placeholder: t('form.bank.placeholder'),
              },
            },
          ],
        },
        /** INN, KPP */
        {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'input',
              name: 'inn',
              label: t('form.inn.label'),
              dataType: 'string',
              props: {
                placeholder: t('form.inn.placeholder'),
              },
            },
            {
              type: 'input',
              name: 'kpp',
              label: t('form.kpp.label'),
              dataType: 'string',
              props: {
                placeholder: t('form.kpp.placeholder'),
              },
            },
          ],
        },
        /** CHECKING ACCOUNT */
        {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'input',
              name: 'checkingAccount',
              label: t('form.checkingAccount.label'),
              props: {
                placeholder: t('form.checkingAccount.placeholder'),
              },
            },
          ],
        },
        /** CORRESPONDENT ACCOUNT */
        {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'input',
              name: 'correspondentAccount',
              label: t('form.correspondentAccount.label'),
              props: {
                placeholder: t('form.correspondentAccount.placeholder'),
              },
            },
          ],
        },
        /** OGRN */
        {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'input',
              name: 'ogrn',
              label: t('form.ogrn.label'),
              props: {
                placeholder: t('form.ogrn.placeholder'),
              },
            },
          ],
        },
        /** SIGNER FULL NAME */
        {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'input',
              name: 'signerFullName',
              label: t('form.signerFullName.label'),
              props: {
                placeholder: t('form.signerFullName.placeholder'),
              },
            },
          ],
        },
        /** SIGNER POSITION */
        {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'input',
              name: 'signerPosition',
              label: t('form.signerPosition.label'),
              props: {
                placeholder: t('form.signerPosition.placeholder'),
              },
            },
          ],
        },
        /** Image */
        {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'dropzone',
              name: 'image',
              label: t('form.image'),
              props: {
                valueKey: 'id',
                srcKey: 'downloadUrl',
                removable: true,
                saveToBackend: saveImage,
                inputAtts: {
                  accept: 'image/png, image/jpeg, image/webp',
                },
              },
            },
          ],
        },
      ],
    },
  },
});
