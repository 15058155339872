import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { saveImage } from 'features/common/file-request';
import type { Entity } from './types';
import {
  create, update, get, remove,
} from './requests';
import { t } from './ru';

export const cardConfig = cardPageController<Entity>({
  header: {
    title: t('form.title'),
  },

  getRequest: get,
  createRequest: create,
  updateRequest: update,
  deleteRequest: remove,

  form: {
    actions: [
      {
        type: 'link',
        to: { name: 'slider' },
        props: {
          text: baseT('form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'input',
          name: 'title',
          label: t('form.name.label'),
          props: {
            required: true,
            placeholder: t('form.name.placeholder'),
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },

        {
          type: 'textarea',
          name: 'description',
          label: t('form.description.label'),
          props: {
            placeholder: t('form.description.placeholder'),
          },
        },

        {
          type: 'input',
          name: 'linkText',
          label: t('form.link_text.label'),
          props: {
            placeholder: t('form.link_text.placeholder'),
          },
        },

        {
          type: 'input',
          name: 'link',
          label: t('form.link.label'),
          props: {
            required: true,
            placeholder: t('form.link.placeholder'),
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },

        {
          type: 'input',
          name: 'sort',
          dataType: 'number',
          label: t('form.sort.label'),
          props: {
            required: true,
          },
          validation: [{
            type: 'empty-required-number' as 'empty',
            trigger: 'change',
          }],
        },

        {
          type: 'dropzone',
          name: 'image',
          label: t('form.image.label'),
          props: {
            required: true,
            valueKey: 'id',
            srcKey: 'downloadUrl',
            saveToBackend: saveImage,
            inputAtts: {
              accept: 'image/png, image/jpeg, image/webp',
            },
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },

        {
          type: 'dropzone',
          name: 'mobileImage',
          label: t('form.mobile_image.label'),
          props: {
            required: true,
            valueKey: 'id',
            srcKey: 'downloadUrl',
            saveToBackend: saveImage,
            inputAtts: {
              accept: 'image/png, image/jpeg, image/webp',
            },
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },
      ],
    },
  },
});
