import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Блог',
    table_title: 'Блог',
    card_title: 'Редактирование статьи',
  },
  table: {
    not_found: 'Статьи не найдены',
    remove_many: 'Удалить выбранное',
    create_new: 'Создать',

    title: 'Заголовок',
    lead: 'Лид',
    active: 'Активен',
  },
  form: {
    name: { label: 'Название', placeholder: 'Новые коллекции' },
    description: { label: 'Короткое описание', placeholder: 'Кружки, ручки, свистелки' },
    publishedAt: { label: 'Дата публикации', placeholder: '02.08.2022' },
    slug: { label: 'ЧПУ-название', placeholder: 'ruchki-krasniye' },
    content: 'Содержание статьи',
    cover: { label: 'Обложка' },
    active: 'Активна',

    h1: { label: 'Заголовок (H1)', placeholder: 'Корпоративные подарки' },
    meta_title: { label: 'Название страницы (Title)', placeholder: 'Корпоративные подарки' },
    meta_keywords: { label: 'Ключевые слова (Keywords)', placeholder: 'Подарки' },
    meta_description: { label: 'Описание (Description)', placeholder: 'Описание' },
    seo: { label: 'SEO' },
  },
};

export const t = ft(ru, 'content.blog.');
