import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import type { Entity } from './types';
import { MapSetPayload } from './types';

const BASE = '/api/admin/mappable';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const type = data.filters.type;
  const search = data.filters.name ? `&filters[0][id]=search&filters[0][value]=${data.filters.name}` : '';

  const sortName = data.sort.name ? `&sort[0][id]=name&sort[0][value]=${data.sort.name}` : '';
  const sortCount = data.sort.count ? `&sort[0][id]=cnt&sort[0][value]=${data.sort.count}` : '';

  return getListResponse<Entity>(`${BASE}/${type}/list?${pagination}${search}${sortName}${sortCount}`, activePage);
});

export const mapProperties = request.custom<any, MapSetPayload>(async ({ data, api, parseError }) => {
  const res = await api.post(`${BASE}/map`, data);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: true,
  };
});

export const deleteAlternatives = request.custom<any, { ids: Array<number> }>(async ({ data, api, parseError }) => {
  const res = await api.post(`${BASE}/delete`, data);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: true,
  };
});
