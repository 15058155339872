import { User } from 'features/users/users/types';
import { request } from '~/utils/request';
import type { NullOrString } from '~/types';
import getUserRole from '~/utils/get-user-role';

type ProxyFunc<BEFORE, AFTER> = (data: BEFORE) => AFTER;

interface UserLogin extends User {
  login: string,
  avatarHigh: NullOrString,
  avatarMedium: NullOrString,
  avatarThumb: NullOrString,
}

export interface Response {
  data: UserLogin,
  authenticated: {
    token: string,
    refreshToken: string,
  },
}

export interface Proxy {
  token: string,
  refreshToken: string,
  user: UserLogin,
}

export const dataToProxy: ProxyFunc<Response, Proxy> = (data) => ({
  token: data.authenticated.token,
  refreshToken: data.authenticated.refreshToken,
  user: data.data,
});

const loginRequest = request.profile(async ({
  data, api, parseError, lstorage,
}) => {
  const res = await api.post<Response>('/api/auth/login', {
    login: data.login?.trim?.(),
    password: data.password,
  });

  if (res.error) {
    const e = parseError(res.error);
    lstorage.delete('token');
    api.instance.defaults({ headers: {} }, false);

    return {
      error: {
        message: Object.keys(e.fields).length ? '' : (e.message || 'Не удалось авторизоваться'),
        fields: e.fields,
      },
    };
  }

  const roles = res.data?.data?.roles;
  const role = getUserRole(roles);

  if (roles && roles?.length && !(role)) {
    lstorage.delete('token');
    api.instance.defaults({ headers: {} }, false);

    return {
      error: {
        message: 'Не удалось авторизоваться',
        fields: {},
      },
    };
  }

  const proxied = dataToProxy(res.data);

  lstorage.put('token', proxied.token);
  lstorage.put('refreshToken', proxied.refreshToken);

  request.api.instance.defaults({
    headers: {
      Authorization: `Bearer ${proxied.token}`,
    },
  });

  return {
    data: {
      user: proxied.user,
      role,
    },
  };
});

export default loginRequest;
