import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { OrderInfoEntity } from 'features/content/order-info/index';
import { EditorField } from '~/utils/editor';
import { t } from './ru';
import {
  get,
  update,
} from './requests';

export const cardConfig = cardPageController<OrderInfoEntity>({
  header: {
    title: t('pages.card_title'),
  },

  getRequest: get,
  updateRequest: update,
  createRequest: update,

  form: {
    actions: [
      {
        type: 'link',
        to: { name: 'news' },
        props: {
          text: baseT('form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** EditorJS under the column */
        {
          type: 'row',
          title: t('form.print_deadline'),
          props: {
            isPaper: true,
            titleType: 'heading',
            elementsGrow: true,
            class: 'editor-js',
          },
          fields: [
            {
              type: 'custom',
              name: 'printDeadline',
              component: () => EditorField,
              props: {
                required: true,
                id: 'editor-print_deadline',
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
          ],
        },
        /** EditorJS under the column */
        {
          type: 'row',
          title: t('form.minimal_order'),
          props: {
            isPaper: true,
            titleType: 'heading',
            elementsGrow: true,
            class: 'editor-js',
          },
          fields: [
            {
              type: 'custom',
              name: 'minimalOrder',
              component: () => EditorField,
              props: {
                required: true,
                id: 'editor-minimal_order',
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
          ],
        },
        /** EditorJS under the column */
        {
          type: 'row',
          title: t('form.payment_types'),
          props: {
            isPaper: true,
            titleType: 'heading',
            elementsGrow: true,
            class: 'editor-js',
          },
          fields: [
            {
              type: 'custom',
              name: 'paymentTypes',
              component: () => EditorField,
              props: {
                required: true,
                id: 'editor-payment_types',
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
          ],
        },
        /** EditorJS under the column */
        {
          type: 'row',
          title: t('form.delivery_info'),
          props: {
            isPaper: true,
            titleType: 'heading',
            elementsGrow: true,
            class: 'editor-js',
          },
          fields: [
            {
              type: 'custom',
              name: 'deliveryInfo',
              component: () => EditorField,
              props: {
                required: true,
                id: 'editor-delivery_info',
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
          ],
        },
      ],
    },
  },
});
