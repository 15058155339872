import { request } from '~/utils/request';

export interface ListResponse<T> {
  data: {
    pageLimit: number,
    countItems: number,
    countPages: number,
    elements: T,
  },
}

export const getListResponse = async <ENTITY>(url: string, page?: number, active?: boolean | null) => {
  const activeFilter = typeof active !== 'undefined' && active
    ? `&filters[100][id]=activeOnly&filters[100][value]=${active ? '1' : '0'}`
    : '';

  const res = await request.api.get<ListResponse<ENTITY[]>>(`${url}${activeFilter}`);

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data?.data.elements,
      pagination: {
        currentPage: page || 1,
        totalPages: res.data?.data.countPages || 1,
        totalItems: res.data?.data.countItems || 0,
      },
    },
  };
};
