import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { get, update } from './requests';
import { t } from './ru';

export const cardConfig = cardPageController<Record<number, number>>({
  header: {
    title: t('pages.card_title'),
  },

  getRequest: get,
  createRequest: update,
  updateRequest: update,

  form: {
    fullDataOnUpdate: true,

    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          fields: [
            {
              type: 'input',
              name: 1,
              label: t('form.1.title'),
              dataType: 'number',
              props: {
                required: true,
                placeholder: t('form.placeholder'),
              },
              validation: [{
                type: 'empty-required-number' as 'empty',
                trigger: 'change',
              }],
            },
            {
              type: 'input',
              name: 2,
              label: t('form.2.title'),
              dataType: 'number',
              props: {
                required: true,
                placeholder: t('form.placeholder'),
              },
              validation: [{
                type: 'empty-required-number' as 'empty',
                trigger: 'change',
              }],
            },
            {
              type: 'input',
              name: 3,
              label: t('form.3.title'),
              dataType: 'number',
              props: {
                required: true,
                placeholder: t('form.placeholder'),
              },
              validation: [{
                type: 'empty-required-number' as 'empty',
                trigger: 'change',
              }],
            },
            {
              type: 'input',
              name: 4,
              label: t('form.4.title'),
              dataType: 'number',
              props: {
                required: true,
                placeholder: t('form.placeholder'),
              },
              validation: [{
                type: 'empty-required-number' as 'empty',
                trigger: 'change',
              }],
            },
          ],
        },
      ],
    },
  },
});
