import {
  filtersFormController,
  tableController,
  tablePageController,
} from 'magner';
import type { OrderList, OrderUser } from './types';
import { getManagers, read } from './requests';
import { t } from './ru';
import getFormatPrice from '~/utils/get-format-price';

const getUserStr = (user: OrderUser) => {
  const userArr = [user.name, user.phone, user.email].filter((item) => !!item).map((item) => `<div>${item}</div>`);

  if (!userArr.length) {
    return '—';
  }

  return userArr.join('');
};

export const tableConfig = tablePageController<OrderList>({
  header: {
    title: t('table.title'),
  },
  request: read,
  table: tableController<OrderList>({
    tableLayout: 'auto',

    emptyText: t('table.not_found'),
    columns: [
      {
        prop: 'id',
        label: t('table.id'),
        sortable: true,
        width: 80,
      },
      {
        prop: 'createdAt',
        label: t('table.createdAt'),
        sortable: true,
        width: 160,
        view: {
          type: 'html',
          formatter: (value) => {
            const [date, time] = (value as string).split(' ');
            return `<div><div>${date.split('-').reverse().join('.')}</div> <div>${time}</div></div>`;
          },
        },
      },
      {
        prop: 'user',
        label: t('table.user'),
        view: {
          type: 'html',
          formatter: (value) => getUserStr(value as OrderUser),
        },
      },
      {
        prop: 'products',
        label: t('table.products'),
        view: {
          type: 'html',
          formatter: (value) => {
            const products = value as OrderList['products'];

            if (!products?.length) {
              return '—';
            }

            const productsString = products.map((item) => `<li>
              <a style="margin-bottom: 8px" class="default-link" href="/catalog/products/${item.id}">
                ${item.title}. <br> Количество: <b>${item.count} шт.</b><br> Цена: <b>${getFormatPrice(item.finalPrice)}</b>
              </a></li>`).join('');

            return `<ol style="padding-left: 22px">${productsString}</ol>`;
          },
        },
      },
      {
        prop: 'totalItemPrice',
        label: t('table.totalItemPrice'),
        width: 140,
        view: {
          type: 'html',
          formatter: (value) => getFormatPrice(value as number),
        },
      },
      {
        prop: 'manager',
        label: t('table.manager'),
        width: 180,
        view: {
          type: 'html',
          formatter: (value) => (value?.id ? getUserStr(value as OrderUser) : 'Без предпочтений'),
        },
      },
    ],
  }),
  filters: filtersFormController<OrderList>({
    saveToLocalStorage: true,

    actions: [],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      id: '',
      createdAt: null,
      manager: null,
    },

    sort: {
      id: '',
    },

    layout: [
      {
        type: 'input',
        name: 'id',
        props: {
          placeholder: t('filters.id_search'),
          type: 'number',
          inputDelay: 400,
        },
      },
      {
        type: 'datetime',
        name: 'createdAt',
        dataType: 'date',
        props: {
          type: 'daterange',
          startPlaceholder: 'От',
          endPlaceholder: 'До',
          format: 'YYYY-MM-DD',
          rangeSeparator: '–',
        },
      },
      {
        type: 'select',
        name: 'manager',
        dataType: 'string',
        options: [],
        props: {
          remote: true,
          clearable: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'name',
          remoteMethod: getManagers,
          placeholder: t('filters.manager'),
        },
      },
    ],
  }),
});
