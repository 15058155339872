import { OrderInfoEntity } from 'features/content/order-info/index';
import { request } from '~/utils/request';
import { filterRequestStrings } from '~/utils/filter-request-strings';

const BASE = '/api/admin/static_block/order_info_block';

export const get = request.card<OrderInfoEntity, OrderInfoEntity>(async ({ api, parseError }) => {
  const isMsk = window.location.href.includes('msk');

  const res = await api.get<{ data: OrderInfoEntity }>(`${BASE}/read${isMsk ? '?city=2' : ''}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  setTimeout(() => {
    const title = document.querySelector('.magner-page-header_title');

    if (title) {
      if (isMsk) {
        title.innerHTML = 'Информация о заказе - Москва';
      } else {
        title.innerHTML = 'Информация о заказе - Основное';
      }
    }
  }, 100);

  return { data: res.data?.data };
});

export const update = request.card<OrderInfoEntity, OrderInfoEntity>(async ({ api, data, parseError }) => {
  filterRequestStrings(data.data);
  const isMsk = window.location.href.includes('msk');

  const res = await api.patch<{ data: OrderInfoEntity }>(`${BASE}/set${isMsk ? '?city=2' : ''}`, data.data);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.data };
});
