import { userGet, UserResponse } from 'features/users/users';
import { request } from '~/utils/request';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import { getListResponse } from '~/utils/get-list';
import type { LegalPerson, LegalPersonCreate, LegalPersonList } from './types';

const BASE = '/api/admin/legal';

export const read = request.table<LegalPersonList>(async ({ data, router }) => {
  const userId = window.location.pathname.replace('/users/', '').replace('/companies', '');
  if (userId === 'new') {
    router.back();
  }

  const userFilters = `&filters[0][id]=user&filters[0][value]=${userId}`;

  const activePage = data.pagination?.page || 1;
  const pagination = `page=${activePage}&limit=${data.pagination.items || 25}`;
  const sort = '';

  const [companies, user] = await Promise.all([
    getListResponse<LegalPersonList>(`${BASE}/list?${pagination}${sort}${userFilters}`, activePage),
    userGet({ id: userId, isNew: false, data: null }),
  ]);

  const headerEl = document.querySelector('.page .magner-page-header_title');
  if (headerEl && user.data) {
    const name = `${user.data.name}`;
    headerEl.textContent = `Юридические лица ${name}`;
  }

  return companies;
});

export const search = request.custom<LegalPersonList[]>(async () => {
  const pagination = 'limit=50&page=1';
  const res = await getListResponse<LegalPersonList>(`${BASE}/list?${pagination}`);
  const proxied: LegalPersonList[] = res.data.rows?.map((legal: LegalPersonList) => ({
    ...legal,
    id: parseInt(legal.id, 10),
  }));
  return { data: proxied };
});

export const get = request.card<LegalPerson, LegalPersonCreate>(async ({ api, data, router }) => {
  const userId = window.location.search.match(/\?user=([^&]+)/)?.[1] || '';

  const [resCompany, resResident] = await Promise.all([
    api.get<{ data: LegalPerson }>(`${BASE}/read/${data.id}`),
    api.get<{ data: UserResponse }>(`/api/admin/user/read/${userId}`),
  ]);

  if (resCompany.error || resResident.error) {
    await router.push({ name: 'users' });
    return { error: { message: 'Не удалось загрузить компанию', fields: {} } };
  }

  return {
    data: {
      ...resCompany.data?.data,
      user: userId,
    },
  };
});

export const create = request.card<LegalPerson, LegalPersonCreate>(async ({
  api, data, parseError, router,
}) => {
  const userId = window.location.search.match(/\?user=([^&]+)/)?.[1] || '';

  filterRequestStrings(data.data);

  const body = {
    ...data.data,
    user: userId,
  };

  if (Array.isArray(body.image)) {
    body.image = body.image[0];
  }

  const res = await api.post<{ data: LegalPerson }>(`${BASE}/create`, body);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'user-companies', params: { id: userId } });
  return { data: res.data?.data };
});

export const update = request.card<LegalPerson, LegalPersonCreate>(async ({ api, data, parseError }) => {
  const userId = window.location.search.match(/\?user=([^&]+)/)?.[1] || '';

  filterRequestStrings(data.data);

  const body = {
    ...data.data,
    user: userId,
  };

  if (Array.isArray(body.image)) {
    if (body.image.length) {
      body.image = body.image[0];
    } else {
      body.image = null;
    }
  } else if (typeof body.image === 'string') {
    delete body.image;
  }

  const res = await api.patch<{ data: LegalPerson }>(`${BASE}/update/${data.id}`, body);

  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.data };
});

export const batch = request.custom<boolean, { ids: string[] }>(async ({ data, api, parseError }) => {
  const res = await api.post(`${BASE}/batch/delete`, { ids: data.ids });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: true,
  };
});

export const remove = request.card<boolean, LegalPersonCreate>(async ({ data }) => batch({
  ids: [data.id as string],
}));
