import { tablePageController, filtersFormController, actionTable } from 'magner';
import { read, remove } from './requests';
import { t } from './ru';
import type { Entity } from './types';

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('pages.table_title'),
  },

  request: read,

  table: {
    rowSelectable: {
      rowIdKey: 'slug',
      actions: [
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<Entity>(async ({ data }) => {
            const ids = data.selected.map((item) => item.slug);
            const res = await remove({ ids });
            return !!res.error;
          }),
          props: {
            text: t('table.remove_many'),
            type: 'danger',
          },
        },
      ],
    },
    emptyText: t('table.not_found'),
    rowLink: (row) => (
      {
        name: 'requirements-edit',
        params: { id: row.slug },
      }
    ),
    columns: [
      {
        prop: 'title',
        label: t('table.title'),
      },
      {
        prop: 'active',
        label: t('table.active'),
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue ? 'Да' : 'Нет'),
        },
      },
    ],
  },

  filters: filtersFormController({
    saveToLocalStorage: true,

    actions: [
      {
        type: 'link',
        to: () => ({ name: 'requirements-edit', params: { id: 'new' } }),
        props: {
          type: 'primary',
          plain: true,
          text: t('table.create_new'),
        },
      },
    ],

    submitEvent: 'input',

    pagination: {
      items: 50,
      page: 1,
    },

    filtersData: {},

    sort: {},

    layout: [],
  }),
});
