import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import type { Entity } from './types';
import { getRemoveAlternativesList } from 'features/mappings/utils';
import { deleteAlternatives } from 'features/mappings/properties/requests';

const BASE = '/api/admin/color';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const search = data.filters.name ? `&filters[0][id]=search&filters[0][value]=${data.filters.name}` : '';
  const sortName = data.sort.name ? `&sort[1][id]=name&sort[1][value]=${data.sort.name}` : '';

  return getListResponse<Entity>(`${BASE}/list?${pagination}${search}${sortName}`, activePage);
});

export const get = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Entity }>(`${BASE}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  const resData = res.data?.data;

  return {
    data: {
      ...resData,
      hex: `#${resData?.hex}`,
      initAlternatives: resData?.alternatives ?? [],
    },
  };
});

export const create = request.card<Entity, Entity>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  filterRequestStrings(data.data);

  const body = {
    ...data.data,
    hex: data.data.hex.replace('#', ''),
  };

  const res = await api.post<{ data: Entity }>(`${BASE}/create`, body);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  router.push({ name: 'base-colors' });

  return { data: res.data?.data };
});

export const update = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  filterRequestStrings(data.data);

  const initAlternatives = data.data.initAlternatives ?? [];
  const changedAlternatives = data.data.alternatives ?? [];

  if (changedAlternatives.length !== initAlternatives.length) {
    const removeAlternatives = getRemoveAlternativesList(initAlternatives, changedAlternatives);

    await deleteAlternatives({ ids: removeAlternatives.map((item) => item.id) });
  }

  const body: Partial<Entity> = {};

  if (data.data.name) {
    body.name = data.data.name;
  }

  if (data.data.hex) {
    body.hex = data.data.hex.replace('#', '');
  }

  const res = await api.patch<{ data: Entity }>(`${BASE}/update/${data.id}`, body);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const batch = request.custom<boolean, { ids: string[] }>(async ({ data, api, parseError, router }) => {
  const res = await api.post(`${BASE}/delete`, { ids: data.ids });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  router.push({ name: 'base-colors' });

  return {
    data: true,
  };
});

export const remove = request.card<boolean, Entity>(async ({ data }) => batch({
  ids: [data.id as string],
}));
