<template>
  <button
    class="remove-btn"
    title="Удалить"
  >
    <svg
      fill="currentColor"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path d="M12 3c-4.963 0-9 4.038-9 9s4.037 9 9 9s9-4.038 9-9s-4.037-9-9-9zm0 16c-3.859 0-7-3.14-7-7s3.141-7 7-7s7 3.14 7 7s-3.141 7-7 7zm.707-7l2.646-2.646a.502.502 0 0 0 0-.707a.502.502 0 0 0-.707 0L12 11.293L9.354 8.646a.5.5 0 0 0-.707.707L11.293 12l-2.646 2.646a.5.5 0 0 0 .707.708L12 12.707l2.646 2.646a.5.5 0 1 0 .708-.706L12.707 12z" />
    </svg>
  </button>
</template>

<style>
.remove-btn {
  padding: 0;
  border: none;
  background-color: transparent;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  color: var(--el-color-danger)
}
</style>
