import {
  tablePageController,
  filtersFormController,
} from 'magner';
import { read } from './requests';
import { t } from './ru';
import type { Entity } from './types';
import DownloadButton from './components/DownloadButton.vue';
import { formatDate } from '~/utils/format-date';

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('table.title'),
  },

  request: read,

  table: {
    emptyText: t('table.not_found'),
    columns: [
      {
        prop: 'supplier',
        label: t('table.supplier'),
        view: {
          type: 'text',
          formatter: (value) => (value.title ? value.title : '-'),
        },
      },
      {
        prop: 'updatedAt',
        label: t('table.updated_at'),
        view: {
          type: 'text',
          formatter: (value) => (value ? formatDate(value) : '-'),
        },
      },
      {
        prop: 'size',
        label: t('table.size'),
        view: {
          type: 'text',
          formatter: (value) => (value ? value : '-'),
        },
      },
      {
        prop: 'downloadLink',
        width: 200,
        view: {
          type: 'custom',
          component: () => DownloadButton,
        },
      },
    ],
  },

  filters: filtersFormController({
    saveToLocalStorage: true,

    actions: [],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {},

    sort: {},

    layout: [],
  }),
});
