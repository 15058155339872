import { tablePageController, filtersFormController } from 'magner';
import { readDynamicTable } from '../requests';
import { t } from '../ru';
import type { Entity } from '../types';

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('table.dynamic_title'),
  },

  request: readDynamicTable,

  table: {
    rowLink: (row) => (
      {
        name: 'seo-dynamic-edit',
        params: { id: row.slug },
      }
    ),
    columns: [
      {
        prop: 'title',
        label: t('table.page_title'),
      },
    ],
  },

  filters: filtersFormController({
    saveToLocalStorage: true,

    actions: [],

    pagination: {
      items: 50,
      page: 1,
    },

    filtersData: {},

    sort: {},

    layout: [],
  }),
});
