import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar: 'Загрузка изображений',
  },
  form: {
    title: 'Загрузка изображений',
    image: { label: 'Изображение', hint: 'При загрузке, в поле ниже появится ссылка на использование изображения.' },
  },
};

export const t = ft(ru, 'settings.images.');
