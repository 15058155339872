import { ft } from '~/utils/feature-translate';

export const baseRu = {
  sidebar: {
    users_group: 'Пользователи',
    catalog_group: 'Каталог',
    catalog_import_group: 'Каталог поставщиков',
    settings_group: 'Настройки',
    content_group: 'Контент',
    contacts_group: 'Контакты',
    delivery_group: 'Доставка',
    order_info_group: 'Информация о заказе',
    seo_group: 'SEO',
    mappings_group: 'Сопоставления',
  },
  filters: {
    active: 'Только активные',
  },
  form: {
    submit_button: 'Сохранить',
    cancel_button: 'Назад',
    remove_button: 'Удалить',
    create_new_button: 'Создать',
  },
};

export const baseT = ft(baseRu, 'base.');
