<template>
  <el-tabs
    v-model="activeTab"
    type="card"
    style="width: 100%"
  >
    <el-tab-pane
      v-for="item in items"
      :key="item.tabId"
      :label="item.title"
      :name="item.tabId"
    >
      <el-card style="width: 100%">
        <template #header>
          <div class="card-header">
            <div style="display: flex; justify-content: space-between">
              <h2>{{ item.title }}</h2>

              <div style="display: flex; gap: 20px">
                <el-button type="success" @click="increasePrices(item.tabId)">+</el-button>
                <div>
                  <el-input-number
                    v-model="pricePercent"
                    :precision="0"
                    :min="0"
                    :max="99"
                  />
                </div>
                <el-button type="danger" @click="decreasePrices(item.tabId)">-</el-button>
              </div>
            </div>
          </div>
        </template>

        <el-table :data="item.table">
          <template v-for="col in item.tableColumns">
            <template v-if="col.prop === 'area'">
              <el-table-column
                fixed="left"
                :prop="col.prop"
                :label="col.label"
                width="180"
              />
            </template>
            <template v-else>
              <el-table-column
                :fixed="col.prop === 'area' ? 'left' : undefined"
                :prop="col.prop"
                :label="col.label"
                width="180"
              >
                <template #default="scope">
                  <el-input-number
                    v-model="item.table[scope.$index][scope.column.property]"
                    :precision="1"
                    :min="0"
                    @change="updateModelValue"
                  />
                </template>
              </el-table-column>
            </template>
          </template>
        </el-table>

        <template v-if="!item.minimalPrices.length">
          <el-form-item label="Минимальная цена" style="margin-bottom: 20px;">
            <el-input-number v-model="item.minimalPrice" :min="1" />
          </el-form-item>
        </template>

        <div v-if="item.options.length" style="margin-top: 40px">
          <template v-for="option in item.options">
            <el-form-item :label="option.title" style="margin-bottom: 20px;">
              <el-input-number v-model="option.value" :disabled="option.customLogic" />
              <p v-if="option.comment" style="padding-left: 10px;color: #888888">{{ option.comment }}</p>
            </el-form-item>
          </template>
        </div>
      </el-card>
    </el-tab-pane>
  </el-tabs>

  <div>
    <h4>Увеличение/уменьшение всех цен</h4>
    <hr>

    <div style="display: flex; gap: 20px">
      <el-button type="success" @click="increaseAllPrices">+</el-button>
      <div>
        <el-input-number
          v-model="priceAllPercent"
          :precision="0"
          :min="0"
          :max="99"
        />
      </div>
      <el-button type="danger" @click="decreaseAllPrices">-</el-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps, defineEmits, PropType, ref, onMounted,
} from 'vue';
import { PricesEntity } from './type';

const props = defineProps({
  form: {
    type: Object as PropType<{items: PricesEntity[]}>,
    required: true,
  },
});

const emits = defineEmits(['update:modelValue']);

const items = ref([...props.form.items]);
const activeTab = ref('');

const updateModelValue = () => {
  emits('update:modelValue', items.value);
};
onMounted(() => {
  emits('update:modelValue', items.value);
});

const changePrices = (itemId: string, coefficient: number) => {
  items.value.map((item: PricesEntity) => {
    if (item.tabId === itemId) {
      item.table.map((row) => {
        Object.keys(row).map((key) => {
          if (key !== 'area') {
            row[key] = +row[key] * coefficient;
          }

          return key;
        });

        return row;
      });
    }

    return item;
  });
};

const priceAllPercent = ref(10);
const pricePercent = ref(10);
/**
 * Метод увеличивает цены вкладки на 10%
 * @param itemId
 */
const increasePrices = (itemId: string) => {
  if (pricePercent.value) {
    changePrices(itemId, (100 + pricePercent.value) / 100);
  }

  updateModelValue();
};

/**
 * Метод уменьшает цены вкладки на 10%
 * @param itemId
 */
const decreasePrices = (itemId: string) => {
  if (pricePercent.value) {
    changePrices(itemId, (100 - pricePercent.value) / 100);
  }

  updateModelValue();
};
/**
 * Метод увеличивает все цены на 10%
 */
const increaseAllPrices = () => {
  if (priceAllPercent.value) {
    items.value.map((item: PricesEntity) => {
      changePrices(item.tabId, (100 + pricePercent.value) / 100);

      return item;
    });
  }

  updateModelValue();
};

/**
 * Метод уменьшает все цены на 10%
 */
const decreaseAllPrices = () => {
  if (priceAllPercent.value) {
    items.value.map((item: PricesEntity) => {
      changePrices(item.tabId, (100 - pricePercent.value) / 100);

      return item;
    });
  }

  updateModelValue();
};
</script>
