import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Бренды',
    table_title: 'Бренды',
  },
  table: {
    not_found: 'Бренды не найдены',
    search: 'Поиск',
    name: 'Название бренда поставщика',
  },
};

export const t = ft(ru, 'catalog_imports.brands.');
