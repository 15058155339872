const addSpaces = (number: string | number | null): string => {
  if (number) {
    // Parse a proper number, allow floats
    const parsed = Number(number).toFixed(2).toString().replace(/[^0-9.]/ig, '');

    // Get integer value of the number
    const [, integ, floating] = parsed.match(/(\d+)\.?,?(\d+)/i) || ['', '0'];
    const fl = (!floating || floating === '00') ? '' : `.${floating}`;

    // Add spaces to the integer part of the number
    return parseInt(integ, 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + fl;
  }

  return '0';
};

export const addSpacesNumeric = (number: string | number | null) => {
  if (!number) {
    return '0';
  }

  const result = addSpaces(number);

  return result?.replace(/[.]/g, ',');
};

export default addSpaces;
