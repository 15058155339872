import {
  tablePageController,
  filtersFormController,
  tableController,
} from 'magner';
import { read } from './requests';
import { t } from './ru';
import type { Entity } from './types';

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('pages.table_title'),
  },

  request: read,

  table: tableController<Entity>({
    emptyText: t('table.not_found'),
    rowLink: (row) => (
      {
        name: 'base-material',
        params: { id: row.id },
      }
    ),
    columns: [
      {
        prop: 'name',
        label: t('table.title'),
        sortable: true,
      },
    ],
  }),

  filters: filtersFormController({
    saveToLocalStorage: false,

    actions: [
      {
        type: 'link',
        to: () => ({ name: 'base-material', params: { id: 'new' } }),
        props: {
          type: 'primary',
          plain: true,
          text: t('table.create_new'),
        },
      },
    ],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      name: '',
    },

    sort: {},

    layout: [
      {
        type: 'input',
        name: 'name',
        props: {
          placeholder: t('table.search'),
          inputDelay: 400,
        },
      },
    ],
  }),
});
