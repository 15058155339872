import { productGet } from 'features/catalog/products';
import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import type { Entity, BatchRequestData, VariationStock } from './types';
import { MultiPriceItem, Warehouse } from './types';

const BASE = '/api/admin/variation';

const updateWareHouses = async (
  variationId: number,
  items: VariationStock[],
) => {
  const data = {
    variation: +variationId,
    stocks: items
      .filter((item) => item.id)
      .map((item) => ({
        warehouse: item.id,
        count: +item.count,
      })),
  };

  const res = await request.api.post<{ data: Entity }>('/api/admin/stock/update', data);

  if (res.error) {
    return { error: res.error };
  }

  return { data: res.data };
};

const updateMultiPrices = async (
  variationId: number,
  items: Array<MultiPriceItem>,
) => {
  const data = {
    prices: items.map((item) => ({
      quantityFrom: +item.quantityFrom,
      quantityTo: item.quantityTo ? +item.quantityTo : null,
      price: +item.price,
    })),
  };

  const res = await request.api.post(`/api/admin/multiprice/update/${variationId}`, data);

  if (res.error) {
    return { error: res.error };
  }

  return { data: res.data };
};

export const read = request.table<Entity>(async ({ data, router }) => {
  const productId = window.location.pathname.replace('/catalog/products/', '').replace('/variations', '');
  if (productId === 'new') {
    router.back();
  }

  const filters = `&filters[0][id]=product&filters[0][value]=${productId}`;

  const activePage = data.pagination?.page || 1;
  const pagination = `page=${activePage}&limit=${data.pagination.items || 25}`;
  const sort = '';

  const [companies, product] = await Promise.all([
    getListResponse<Entity>(`${BASE}/list?${pagination}${sort}${filters}`, activePage),
    productGet({ id: productId, isNew: false, data: null }),
  ]);

  const headerEl = document.querySelector('.page .magner-page-header_title');
  if (headerEl && product.data) {
    const name = `${product.data.title}`;
    headerEl.textContent = `Вариации товара "${name}"`;
  }

  return companies;
});

export const search = request.custom<Entity[]>(async () => {
  const pagination = 'limit=50&page=1';
  const res = await getListResponse<Entity>(`${BASE}/list?${pagination}`);
  return { data: res.data.rows };
});

export const get = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  const productId = window.location.search.match(/\?product=([^&]+)/)?.[1] || '';

  const [
    resVariation,
    resProduct,
    resMultiPrice,
  ] = await Promise.all([
    api.get<{ data: Entity }>(`${BASE}/read/${data.id}`),
    productGet({ id: productId, isNew: false, data: null }),
    api.get<{ data: { items: Array<MultiPriceItem> } }>(`/api/admin/multiprice/list/${data.id}`),
  ]);

  if (resVariation.error || resProduct.error) {
    return { error: { message: 'Не удалось загрузить вариацию', fields: {} } };
  }

  const multiPrices = resMultiPrice?.data?.data?.items.map((item) => ({
    ...item,
    price: item.price.inRubles,
  })) ?? [];

  return {
    data: {
      ...resVariation.data?.data,
      product: productId,
      productVariationStocks: resVariation.data?.data.productVariationStocks
        .map((item) => ({
          count: item.count,
          id: item.warehouse.id,
        })),
      multiPrices,
    },
  };
});

export const getNew = request.card(async ({ api, data, parseError }) => {
  const productId = window.location.search.match(/\?product=([^&]+)/)?.[1] || '';

  const resProduct = await productGet({ id: productId, isNew: true, data: null });

  if (resProduct.error) {
    return { error: { message: 'Не удалось загрузить вариацию', fields: {} } };
  }

  return {
    data: {
      product: productId,
    },
  };
});

export const create = request.card<Entity, Entity>(async ({
  api, data, parseError, router,
}) => {
  const productId = window.location.search.match(/\?product=([^&]+)/)?.[1] || '';

  filterRequestStrings(data.data);

  const res = await api.post<{ data: Entity }>(`${BASE}/create`, {
    ...data.data,
    product: productId,
  });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'product-variations', params: { id: productId } });
  return { data: res.data?.data };
});

export const update = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  filterRequestStrings(data.data);

  const body = data.data;

  if (body.productVariationStocks) {
    const resWareHouses = await updateWareHouses(
      data.id as number,
      body.productVariationStocks,
    );

    if (resWareHouses.error) {
      return { error: parseError(resWareHouses.error) };
    }
  }

  delete body.productVariationStocks;

  if (body.multiPrices) {
    const resMultiPrices = await updateMultiPrices(
      data.id as number,
      body.multiPrices,
    );

    if (resMultiPrices.error) {
      return { error: parseError(resMultiPrices.error) };
    }
  }

  delete body.multiPrices;

  const res = await api.patch<{ data: Entity }>(
    `${BASE}/update/${data.id}`,
    body,
  );

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const batch = request.custom<boolean, BatchRequestData>(async ({ data, api, parseError }) => {
  const res = await api.post(`${BASE}/batch/${data.type}`, { ids: data.ids });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: true,
  };
});

export const remove = request.card<boolean, Entity>(async ({ data }) => batch({
  ids: [data.id as string],
  type: 'delete',
}));

export const getWareHousesOptions = request.custom(async (data) => {
  const ls = localStorage.getItem('magner-toomanygifts');

  let wareHouses: Warehouse[] = [];
  if (ls) wareHouses = JSON.parse(ls).wareHouses;
  const activeVariationStocks = data.data.form.productVariationStocks ?? [];

  if (activeVariationStocks.length) {
    activeVariationStocks.forEach((activeVariation: { id: string }) => {
      wareHouses.forEach((wareHouse) => {
        if (activeVariation.id === wareHouse.id) wareHouse.disabled = true;
      });
    });
  }

  return { data: wareHouses };
});
