import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { EditorField } from '~/utils/editor';
import type { Entity, SupportedPages } from './types';
import { t } from './ru';
import { get, update } from './requests';

export const getCardConfig = (name: SupportedPages) => cardPageController<Entity>({
  header: {
    title: t(`form.title.${name}`),
  },

  getRequest: get(name),
  createRequest: update(name),
  updateRequest: update(name),

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'column',
      title: t('form.content'),
      props: {
        isPaper: true,
        titleType: 'heading',
        class: 'editor-js',
      },
      fields: [
        {
          type: 'custom',
          name: 'content',
          component: () => EditorField,
          props: {
            id: `editor-${name}`,
          },
        },
      ],
    },
  },
});
