import { ft } from '~/utils/feature-translate';
import type { SupportedPages } from './types';

export const ru = {
  sidebar: {
    title: {
      delivery: 'Основное',
      delivery_msk: 'Москва',
      payment: 'Оплата',
      'obmen-vozvrat-tovarov': 'Обмен и возврат',
      privacy: 'Политика конфиденциальности',
      'public-offer': 'Публичная оферта',
      about: 'О компании',
      agreement: 'Обработка персональных данных',
      // requirements: 'Требования к нанесению',
    } as Record<SupportedPages, string>,
  },
  form: {
    title: {
      delivery: 'Доставка - Основное',
      delivery_msk: 'Доставка - Москва',
      payment: 'Оплата',
      'obmen-vozvrat-tovarov': 'Обмен и возврат',
      privacy: 'Политика конфиденциальности',
      'public-offer': 'Публичная оферта',
      about: 'О компании',
      agreement: 'Согласие на обработку персональных данных',
      // requirements: 'Требования к нанесению',
    } as Record<SupportedPages, string>,
    content: 'Содержание',
  },
};

export const t = ft(ru, 'content.pages.');
