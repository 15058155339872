import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import {
  get,
  create,
  update,
  remove,
  getNew, getWareHousesOptions,
} from './requests';
import type { Entity } from './types';
import { t } from './ru';

export const cardConfig = cardPageController<Entity>({
  header: {
    title: t('sidebar.card_title'),
  },

  getRequest: get,
  getNewRequest: getNew,
  createRequest: create,
  updateRequest: update,
  deleteRequest: remove,

  form: {
    actions: [
      {
        type: 'link',
        to: (route) => ({ name: 'product-variations', params: { id: route.query.product } }),
        props: {
          text: baseT('form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'sku',
                  label: t('form.sku.label'),
                  props: {
                    required: true,
                    placeholder: t('form.sku.placeholder'),
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'change',
                  }],
                },
                {
                  type: 'input',
                  name: 'sizeValue',
                  label: t('form.size.label'),
                  props: {
                    placeholder: t('form.size.placeholder'),
                  },
                },
                {
                  type: 'input',
                  name: 'price',
                  label: t('form.price.label'),
                  props: {
                    required: true,
                    placeholder: t('form.price.placeholder'),
                  },
                  validation: [{
                    type: 'empty-required-price' as 'empty',
                    trigger: 'change',
                  }],
                },
              ],
            },
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
            hidden: ({ isNew }) => isNew,
          },
          layout: [
            {
              type: 'column',
              title: t('form.multi_prices.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'collection',
                  name: 'multiPrices',
                  dataType: 'array',
                  props: {
                    class: 'variation-multi-price-collection',
                    firstRemovable: true,
                  },
                  layout: [
                    {
                      type: 'row',
                      props: { elementsGrow: true },
                      fields: [
                        {
                          type: 'input',
                          name: 'quantityFrom',
                          label: t('form.multi_prices.quantity_from.label'),
                          dataType: 'number',
                          props: {
                            placeholder: t('form.multi_prices.quantity_from.placeholder'),
                            required: true,
                            type: 'number',
                          },
                          validation: [{
                            type: 'empty-required-number' as 'empty',
                            trigger: 'change',
                          }],
                        },
                        {
                          type: 'input',
                          name: 'quantityTo',
                          label: t('form.multi_prices.quantity_to.label'),
                          dataType: 'number',
                          props: {
                            placeholder: t('form.multi_prices.quantity_to.placeholder'),
                            type: 'number',
                          },
                        },
                        {
                          type: 'input',
                          name: 'price',
                          label: t('form.multi_prices.price.label'),
                          dataType: 'number',
                          props: {
                            placeholder: t('form.multi_prices.price.placeholder'),
                            required: true,
                            type: 'number',
                          },
                          validation: [{
                            type: 'empty-required-price' as 'empty',
                            trigger: 'change',
                          }],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: 'column',
              title: t('form.stock.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'collection',
                  name: 'productVariationStocks',
                  dataType: 'array',
                  props: {
                    class: 'variation-stock-collection',
                    firstRemovable: true,
                  },
                  layout: [
                    {
                      type: 'row',
                      props: { elementsGrow: true },
                      fields: [
                        {
                          type: 'select',
                          name: 'id',
                          dataType: 'string',
                          options: [],
                          label: t('form.stock.warehouse.label'),
                          props: {
                            valueKey: 'id',
                            labelKey: 'title',
                            placeholder: t('form.stock.warehouse.placeholder'),
                            remote: true,
                            remoteMethod: getWareHousesOptions,
                            loadRemoteMethodOnFocus: true,
                            required: true,
                          },
                          validation: [{
                            type: 'empty-required' as 'empty',
                            trigger: 'change',
                          }],
                        },
                        {
                          type: 'input',
                          name: 'count',
                          label: t('form.stock.count.label'),
                          dataType: 'number',
                          props: {
                            placeholder: t('form.stock.count.placeholder'),
                            required: true,
                          },
                          validation: [{
                            type: 'empty-required-number' as 'empty',
                            trigger: 'change',
                          }],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
