import { baseColorsTableModalConfig } from 'features/mappings/base-colors';
import { baseMaterialsTableModalConfig } from 'features/mappings/base-materials';
import {
  tablePageController,
  filtersFormController,
  tableController,
  actionTable,
  openModal,
} from 'magner';
import { mapProperties, read } from './requests';
import { t } from './ru';
import type { Entity } from './types';
import { MapSetPayload } from './types';

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('pages.table_title'),
  },

  request: read,

  table: tableController<Entity>({
    rowSelectable: {
      rowIdKey: 'name',
      reserveSelection: true,
      actions: [
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<Entity>(async ({ data }) => {
            const alternatives = data.selected.map((item) => item.name);
            const type: MapSetPayload['type'] = data.filters.type;

            const mapData: MapSetPayload = {
              alternatives,
              hosts: [],
              type,
            };
            const selectedBaseProperties = (event: Event) => {
              mapData.hosts = event.detail.hosts;
            };

            document.addEventListener('map_properties', selectedBaseProperties);

            await openModal<any>({
              type: 'table',
              config: type === 'color' ? baseColorsTableModalConfig : baseMaterialsTableModalConfig,
              props: {},
            });

            await mapProperties(mapData);

            document.removeEventListener('map_properties', selectedBaseProperties);
            return false;
          }),
          props: {
            text: t('table.mapping_modal_button'),
            type: 'primary',
          },
        },
      ],
    },
    emptyText: t('table.not_found'),
    columns: [
      {
        prop: 'name',
        label: t('table.title'),
        sortable: true,
      },
      {
        prop: 'count',
        label: t('table.count'),
        sortable: true,
      },
    ],
  }),

  filters: filtersFormController({
    saveToLocalStorage: false,

    actions: [],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      name: '',
      type: 'color',
    },

    sort: {},

    layout: [
      {
        type: 'select',
        name: 'type',
        dataType: 'string',
        options: [
          {
            id: 'color',
            title: 'Цвета',
          },
          {
            id: 'material',
            title: 'Материалы',
          },
        ],
        props: {
          valueKey: 'id',
          labelKey: 'title',
        },
      },
      {
        type: 'input',
        name: 'name',
        props: {
          placeholder: t('table.search'),
          inputDelay: 400,
        },
      },
    ],
  }),
});
