import { ft } from '~/utils/feature-translate';

export const ru = {
  sidebar: {
    table_title: 'Заказы',
  },
  table: {
    not_found: 'Заказы не найдены',

    title: 'Заказы',

    id: 'ID',
    createdAt: 'Дата и время',
    user: 'Покупатель',
    products: 'Состав заказа',
    manager: 'Менеджер',
    totalItemPrice: 'Цена заказа',
  },
  filters: {
    id_search: 'Поиск по ID',
    manager: 'Менеджер',
  },
  form: {},
};

export const t = ft(ru, 'orders.');
