import {
  actionTable, filtersFormController, tableController, tablePageController,
} from 'magner';
import { baseT } from 'configs/translation/base';
import type { Entity } from './types';
import { read, batch } from './requests';
import { t } from './ru';

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('sidebar.table_title'),
    tabs: [
      {
        label: t('form.tabs.product'),
        link: { name: 'product' },
        active: false,
      },
      {
        label: t('form.tabs.variations'),
        link: { name: 'product-variations' },
        active: true,
      },
    ],
  },

  request: read,
  table: tableController<Entity>({
    rowSelectable: {
      rowIdKey: 'id',
      actions: [
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<Entity>(async ({ data }) => {
            const ids = data.selected.map((item) => item.id);
            const res = await batch({ ids: ids as unknown as string[], type: 'activate' });
            return !!res.error;
          }),
          props: {
            text: t('table.active_many'),
            type: 'primary',
          },
        },
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<Entity>(async ({ data }) => {
            const ids = data.selected.map((item) => item.id);
            const res = await batch({ ids: ids as unknown as string[], type: 'deactivate' });
            return !!res.error;
          }),
          props: {
            text: t('table.remove_many'),
            type: 'danger',
          },
        },
      ],
    },
    emptyText: t('table.not_found'),
    rowLink: (row) => {
      const productId = window.location.pathname.replace('/catalog/products/', '').replace(/\/variations.*/, '');
      return { name: 'product-variation', params: { id: row.id }, query: { product: productId } };
    },
    columns: [
      {
        prop: 'sku',
        label: t('table.sku'),
      },
      {
        prop: 'size',
        label: t('table.size'),
      },
      {
        prop: 'oldPrice',
        label: t('table.old_price'),
      },
      {
        prop: 'price',
        label: t('table.price_with_discount'),
      },
      {
        prop: 'active',
        label: t('table.active'),
        width: 150,
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue ? 'Да' : 'Нет'),
        },
      },
    ],
  }),
  filters: filtersFormController<Entity>({
    saveToLocalStorage: true,

    actions: [
      {
        type: 'link',
        to: (route) => ({ name: 'product-variation', params: { id: 'new' }, query: { product: route.params.id } }),
        props: {
          type: 'primary',
          text: baseT('form.create_new_button'),
        },
      },
    ],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      name: '',
      phone: '',
    },

    sort: {},

    layout: [],
  }),
});
