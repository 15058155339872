import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { get, update } from './requests';
import { t } from './ru';
import PricesTable from './table.vue';

export const cardConfig = cardPageController<Record<number, number>>({
  header: {
    title: t('pages.card_title'),
  },

  getRequest: get,
  createRequest: update,
  updateRequest: update,

  form: {
    fullDataOnUpdate: true,

    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          title: '',
          props: { span: 24 },
          fields: [
            {
              type: 'custom',
              name: 'items',
              dataType: 'object',
              component: () => PricesTable,
              props: {},
            },
          ],
        },
      ],
    },
  },
});
