import {
  tablePageController,
  filtersFormController,
  tableController,
  actionTable,
} from 'magner';
import { supplierAllSearch } from 'features/catalog/supplier';
import { read } from './requests';
import { t } from './ru';
import type { Entity } from './types';
import CategoryCascader from './components/category-cascader.vue';
import SetManyCategoriesAction from './components/set-many-categories-action.vue';

const defaultSupplierId = localStorage.getItem('defaultSupplierId') ?? 1;

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('pages.table_title'),
  },

  request: read,

  table: tableController<Entity>({
    emptyText: t('table.not_found'),
    rowSelectable: {
      rowIdKey: 'id',
      customActions: [
        {
          emits: 'deselect-and-update',
          component: () => SetManyCategoriesAction,
        },
      ],
    },
    rowTree: {
      key: 'id',
      childrenListKey: 'categories',
    },
    columns: [
      {
        prop: 'name',
        label: t('table.name'),
      },
      {
        prop: 'supplierId',
        label: t('table.to_products'),
        view: {
          type: 'actions',
          actions: [
            {
              type: 'action',
              emits: 'update-table',
              action: actionTable<Entity>(async ({ data, router }) => {
                const category = (data as any).row as Entity;

                if (!category.supplierId) {
                  console.error('Нет id поставщика');
                  return true;
                }

                await router.push({
                  name: 'products-import',
                  query: {
                    data: encodeURI(JSON.stringify({
                      filters: {
                        supplierId: category.supplierId,
                        categoryId: category.id,
                      },
                    })),
                  },
                });
                return false;
              }),
              props: {
                text: t('table.to_products_action_btn'),
                type: 'primary',
                plain: true,
              },
            },
          ],
        },
      },
      {
        prop: 'category',
        label: t('table.categories'),
        view: {
          type: 'custom',
          component: () => CategoryCascader,
        },
      },
    ],
  }),

  filters: filtersFormController({
    saveToLocalStorage: true,

    actions: [],

    submitEvent: 'input',

    pagination: {
      items: 10,
      page: 1,
    },

    filtersData: {
      supplierId: +defaultSupplierId,
    },

    sort: {},

    layout: [
      {
        type: 'select',
        name: 'supplierId',
        dataType: 'string',
        options: [],
        props: {
          filterable: true,
          remote: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'title',
          remoteMethod: supplierAllSearch,
          placeholder: t('table.supplier'),
        },
      },
    ],
  }),
});
