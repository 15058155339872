<template>
  <ElButton
    type="primary"
    plain
    :disabled="!row.downloadLink || (!row.size && !row.updatedAt)"
    @click="download"
  >
    Скачать
  </ElButton>
</template>

<script lang="ts" setup>
import { defineProps, PropType } from 'vue';
import { Entity } from 'features/exports/types';

const props = defineProps({
  row: {
    type: Object as PropType<Entity>,
    required: true,
  },
});

const download = () => {
  if (props.row?.downloadLink) {
    const link = document.createElement('a');
    link.href = props.row.downloadLink;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};
</script>
