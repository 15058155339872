import { ft } from '~/utils/feature-translate';

export const ru = {
  sidebar: {
    table_title: 'Юридические лица',
    card_title: 'Юридическое лицо',
  },
  table: {
    title: 'Юридические лица',
    not_found: 'Юридические лица не найдены',
    remove_many: 'Удалить выбранное',
    short_title: 'Название',
    address: 'Адрес',
    bank_title: 'Банк',
    inn: 'ИНН',
    kpp: 'КПП',
    checking_account: 'Счет',
  },
  filters: {},
  form: {
    title: 'Юридическое лицо',
    tabs: {
      user: 'Пользователь',
      companies: 'Компании',
    },

    confirm_delete: 'Вы уверены, что хотите удалить данное юридическое лицо?',
    short_title: { label: 'Название', placeholder: 'ООО "Какие Люди"' },
    address: { label: 'Юридический адрес', placeholder: 'г. Москва' },
    bank: { label: 'Банк', placeholder: 'Русский банк' },
    inn: { label: 'ИНН', placeholder: '100101001010' },
    kpp: { label: 'КПП', placeholder: '12312432412' },
    checkingAccount: { label: 'Рассчетный счет', placeholder: '234121001030' },
    correspondentAccount: { label: 'Корреспондентский счет', placeholder: '3003204023401' },
    signerFullName: { label: 'ФИО Подписанта', placeholder: 'Иванов Иван Иванович' },
    signerPosition: { label: 'Должность подписанта', placeholder: 'Исполнительный директор' },
    ogrn: { label: 'ОГРН', placeholder: '8817976252414' },
    image: 'Изображение',
  },
};

export const t = ft(ru, 'users.legal.');
