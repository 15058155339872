import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Категории',
    table_title: 'Категория',
    card_title: 'Редактирование категории',
  },
  table: {
    create_new: 'Создать',
    not_found: 'Категории не найдены',
    remove_many: 'Деактивировать выбранное',
    active_many: 'Активировать выбранное',
    search: 'Поиск',
    sort: 'Сортировка',

    title: 'Заголовок',
    image: 'Изображение',
    parent: 'Родительская категория',
    active: { label: 'Активирована', yes: 'Да', no: 'Нет' },
  },
  form: {
    name: { label: 'Название', placeholder: 'Футболки' },
    h1: { label: 'Заголовок (H1)', placeholder: 'Футболки' },
    menu_title: { label: 'Название в боковом меню', placeholder: 'Футболки' },
    slug: { label: 'ЧПУ-название', placeholder: 'futbolki' },
    sort: { label: 'Сортировка', placeholder: '100' },
    meta_title: { label: 'Название страницы (Title)', placeholder: 'Футболки' },
    meta_keywords: { label: 'Ключевые слова (Keywords)', placeholder: 'Футболки мужские, майки' },
    meta_description: { label: 'Описание (Description)', placeholder: 'Описание' },
    seo: { label: 'SEO' },
    image: { label: 'Обложка' },
    parent: { label: 'Основная родительская категория', placeholder: 'Выбрать' },
    parents: { label: 'Дополнительные родительские категории', placeholder: 'Выбрать' },
    content_top: 'Наполнение над товарами',
    content_bottom: 'Наполнение под товарами',
    size_filter_active: {
      label: 'Активность фильтра "Размер"',
    },
  },
};

export const t = ft(ru, 'catalog.categories.');
