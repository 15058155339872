import { Entity } from 'features/catalog-import/categories/types';
import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import type { OrderList } from './types';
import { roundToTwoSigns } from '~/utils/format-date';

const BASE = '/api/admin/order';

export const read = request.table<OrderList>(async ({ data }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `page=${activePage}&limit=${data.pagination.items || 25}`;

  const filterId = data.filters.id ? `&filters[0][id]=order_id&filters[0][value]=${data.filters.id}` : '';

  let filterDateFrom = '';
  let filterDateTo = '';
  if (data.filters.createdAt) {
    const dateFrom = new Date(data.filters.createdAt[0]);
    const dateFromFormat = `${dateFrom.getFullYear()}-${roundToTwoSigns(dateFrom.getMonth() + 1)}-${roundToTwoSigns(dateFrom.getDate())}`;
    filterDateFrom = `&filters[1][id]=created_begin&filters[1][value]=${dateFromFormat}`;

    const dateTo = new Date(data.filters.createdAt[1]);
    const dateToFormat = `${dateTo.getFullYear()}-${roundToTwoSigns(dateTo.getMonth() + 1)}-${roundToTwoSigns(dateTo.getDate())}`;
    filterDateTo = `&filters[2][id]=created_end&filters[2][value]=${dateToFormat}`;
  }
  const filterManager = data.filters.manager ? `&filters[3][id]=manager_id&filters[3][value]=${data.filters.manager}` : '';

  const sortID = data.sort.id ? `&sort[0][id]=id&sort[0][value]=${data.sort.id}` : '';
  const sortCreatedAt = data.sort.createdAt ? `&sort[1][id]=createdAt&sort[1][value]=${data.sort.createdAt}` : '';

  return getListResponse<OrderList>(`${BASE}/list?${pagination}${filterId}${filterDateFrom}${filterDateTo}${filterManager}${sortID}${sortCreatedAt}`, activePage);
});

export const getManagers = request.custom<Entity>(async ({ data, parseError }) => {
  const res = await request.api.get<{ data: { managers: Array<{id: string, name: string}> } }>('/api/order/manager');

  if (res.data) {
    return {
      data: [
        { id: 'none', name: 'Без предпочтений' },
        ...res.data.data.managers,
      ],
    };
  }

  return { error: parseError(res.error) };
});
