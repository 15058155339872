<template>
  <ElSelect
    v-model="synonyms"
    multiple
    filterable
    allow-create
    default-first-option
    :placeholder="field.placeholder"
    :no-data-text="field.noDataText"
    @change="changeValue"
    @remove-tag="clear"
  >
    <ElOption
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </ElSelect>
</template>

<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  ref,
  PropType,
} from 'vue';
import { ElSelect, ElOption } from 'magner/element-plus';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  form: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Array as PropType<string[]>,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const options = [];
const synonyms = ref<string[]>(props.modelValue);

const changeValue = (value: string[]) => {
  // При обновлении мы не можем удалить элемент
  if (value.length < props.modelValue.length) {
    synonyms.value = props.modelValue;
    emit('update:modelValue', props.modelValue);
    return;
  }

  synonyms.value = value;
  emit('update:modelValue', value);
};

const clear = (removeTag: string) => {
  synonyms.value = synonyms.value.filter((item) => item !== removeTag);
  emit('update:modelValue', synonyms.value);
};
</script>
