import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Варианты нанесения',
    table_title: 'Варианты нанесения',
    card_title: 'Вариант нанесения',
  },
  table: {
    title: 'Название',
    create_new: 'Создать',
    not_found: 'Новости не найдены',
    remove_many: 'Удалить выбранное',
    active: 'Активен',
  },
  form: {
    title: { label: 'Название', placeholder: 'Шелкография' },
    slug: { label: 'ЧПУ-название', placeholder: 'shelkografiya' },
    description: { label: 'Короткое описание', placeholder: 'Прямая трафаретная печать на материалах разных фактур, популярна для футболок, брелков, упаковочных материалов и бизнес-сувениров.' },
    hint: 'Если добавить заголовок второго уровня с названием "Требования к макетам", на него автоматически будут переходить пользователи из соответствующего раздела на публичном сайте.',
    content: 'Содержание',
    image: { label: 'Обложка' },
    alternatives: { label: 'Синонимы', placeholder: 'Тиснение блинтовое' },

    h1: { label: 'Заголовок (H1)', placeholder: 'Корпоративные подарки' },
    meta_title: { label: 'Название страницы (Title)', placeholder: 'Корпоративные подарки' },
    meta_keywords: { label: 'Ключевые слова (Keywords)', placeholder: 'Подарки' },
    meta_description: { label: 'Описание (Description)', placeholder: 'Описание' },
    seo: { label: 'SEO' },
    active: 'Активен',
  },
};

export const t = ft(ru, 'content.requirements.');
