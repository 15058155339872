<template>
  <Cascader
    class="set-many-categories-action"
    :field="{
      remoteMethod: searchIsActive,
      checkStrictly: true,
      value: 'id',
      label: 'title',
      children: 'categories',
      filterable: true,
      placeholder: 'Указать рубрику основного каталога',
      initOnMount: true,
    }"
    @update:modelValue="setCategories"
  />
</template>

<script lang="ts" setup>
import { defineEmits, defineProps } from 'vue';
import { magnerMessage } from 'magner';
import { setParentCategory } from 'features/catalog-import/categories/requests';
import { categoryHierarchySearch } from 'features/catalog/categories';
import { Entity } from 'features/catalog-import/products/types';

import Cascader from '~/components/cascader/cascader.vue';

const props = defineProps({
  requestData: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['action']);

const setCategories = async (category: string) => {
  const res = await setParentCategory({
    categoryId: category,
    ids: props.requestData.selected.map((item: Entity) => item.id),
    supplierId: props.requestData.filters.supplierId,
  });

  if (res.data) {
    magnerMessage({
      type: 'success',
      message: 'Успешно изменено',
    });

    emit('action');
    return;
  }

  magnerMessage({
    type: 'error',
    message: res.error?.message,
  });
};

const searchIsActive = () => categoryHierarchySearch({ isActive: true });
</script>
