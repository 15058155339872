import {
  tablePageController, filtersFormController, tableController, actionTable,
} from 'magner';
import { read, batch } from './requests';
import { t } from './ru';
import type { Entity } from './types';

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('pages.table_title'),
  },

  request: read,

  table: tableController<Entity>({
    rowSelectable: {
      rowIdKey: 'id',
      actions: [
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<Entity>(async ({ data }) => {
            const ids = data.selected.map((item) => item.id);
            const res = await batch({ ids });
            return !!res.error;
          }),
          props: {
            text: t('table.remove_many'),
            type: 'danger',
          },
        },
      ],
    },
    emptyText: t('table.not_found'),
    rowLink: (row) => (
      {
        name: 'brand',
        params: { id: row.id },
      }
    ),
    columns: [
      {
        prop: 'title',
        label: t('table.title'),
        sortable: true,
      },
      {
        prop: 'image',
        label: t('table.image'),
        view: {
          type: 'image',
          formatter: (_, row) => row.image || '/empty.png',
        },
      },
      {
        prop: 'active',
        label: t('table.active.label'),
        width: 150,
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue ? 'Да' : 'Нет'),
        },
      },
    ],
  }),

  filters: filtersFormController({
    saveToLocalStorage: false,

    actions: [
      {
        type: 'link',
        to: () => ({ name: 'brand', params: { id: 'new' } }),
        props: {
          type: 'primary',
          plain: true,
          text: t('table.create_new'),
        },
      },
    ],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      title: '',
    },

    sort: {},

    layout: [
      {
        type: 'input',
        name: 'title',
        props: {
          placeholder: t('table.search'),
          inputDelay: 400,
        },
      },
    ],
  }),
});
