import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Товары',
    table_title: 'Товары',
    card_title: 'Редактирование товара',
  },
  table: {
    create_new: 'Создать',
    not_found: 'Товары не найдены',
    remove_many: 'Деактивировать выбранное',
    active_many: 'Активировать выбранное',
    search: 'Поиск',
    add_products: 'Добавить товары',
    add_products_failed: 'Ошибка',

    title: 'Название',
    image: 'Изображение',
    sku: 'Артикул',
    brand: 'Бренд',
    supplier: 'Поставщик',
    category: 'Категория',
    active: 'Активен',
  },
  form: {
    tabs: {
      product: 'Товар',
      variations: 'Вариации',
    },

    name: { label: 'Заголовок', placeholder: 'Новые коллекции' },
    sku: { label: 'Артикул', placeholder: '' },
    description: { label: 'Описание', placeholder: '' },
    discount: { label: 'Скидка', type: 'Тип скидки', placeholder: '50' },
    image: { label: 'Обложка' },
    category: { label: 'Основная категория', placeholder: '' },
    second_category: { label: 'Второстепенные категории', placeholder: '' },
    brand: { label: 'Бренд', placeholder: '' },
    supplier: { label: 'Поставщик', placeholder: '' },
    external: { label: 'Внешний ID для интеграций от поставщика', placeholder: '' },
    min_price: { label: 'Минимальная цена вариаций' },
    cause_of_deactivation: { label: 'Причина смены активации' },
    active: { label: 'Активность' },
    draft: { label: 'Показывать только менеджерам' },
    properties: {
      title: 'Детали',
      type: 'Тип детали',
      value: 'Значение детали',
    },
    recommended: { label: 'Рекомендуемый товар' },
    ignore_stock: { label: 'Всегда в наличии' },
    import_disabled: { label: 'Не импортировать' },
    created_at: { label: 'Дата создания', placeholder: '' },
    last_imported_at: { label: 'Дата и время последнего импорта', placeholder: '' },
  },
};

export const t = ft(ru, 'catalog.products.');
