import { ft } from '~/utils/feature-translate';

export const ru = {
  sidebar: {
    table_title: 'Баннеры на главной',
  },
  table: {
    title: 'Баннеры на главной',
    not_found: 'Баннеры не найдены',
    banner_title: 'Заголовок',
    description: 'Короткое описание',
    mobile_image: 'Изображение для мобильных экранов',
    desktop_image: 'Изображение для больших экранов',
  },
  form: {
    title: 'Редактирование баннера',
    name: { label: 'Заголовок', placeholder: 'Новые коллекции' },
    description: { label: 'Короткое описание', placeholder: 'Кружки, ручки, свистелки' },
    link: { label: 'Ссылка', placeholder: '/privacy' },
    desktop: { label: 'Изображение для больших экранов' },
    mobile: { label: 'Изображение для мобильных экранов' },
  },
};

export const t = ft(ru, 'settings.banner.');
