const urlToTranslit = (word: string) => {
  const converter = {
    'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
    'е': 'e',    'ё': 'yo',   'ж': 'zh',   'з': 'z',    'и': 'i',
    'й': 'j',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
    'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
    'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
    'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
    'э': 'e',    'ю': 'yu',   'я': 'ya',
  };

  word = word.toLowerCase();

  let answer = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < word.length; i++) {
    // @ts-ignore
    if (converter[word[i]] === undefined) {
      answer += word[i];
    } else {
      // @ts-ignore
      answer += converter[word[i]];
    }
  }

  answer = answer.replace(/[^-0-9a-z]/g, '-');
  answer = answer.replace(/[-]+/g, '-');
  answer = answer.replace(/^\-|-$/g, '');
  return answer;
};

export default urlToTranslit;
