import {
  tablePageController,
  filtersFormController,
  tableController,
  actionTable,
} from 'magner';
import { supplierOnlyHasItemsSearch } from 'features/catalog/supplier';
import { categoryOnlyActiveListSearch } from 'features/catalog/categories';
import { brandSearchOnlyHasItems } from 'features/catalog/brands';
import { baseT } from 'configs/translation/base';
import type { EntityTable } from './types';
import { read, batch } from './requests';
import { t } from './ru';
import SetSecondCategoryToMany from './components/set-second-category-to-many.vue';

export const tableConfig = tablePageController<EntityTable>({
  header: {
    title: t('pages.table_title'),
  },

  request: read,

  table: tableController<EntityTable>({
    rowSelectable: {
      rowIdKey: 'id',
      actions: [
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<EntityTable>(async ({ data }) => {
            const ids = data.selected.map((item) => item.id);
            const res = await batch({ ids, type: 'activate' });
            return !!res.error;
          }),
          props: {
            text: t('table.active_many'),
          },
        },
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<EntityTable>(async ({ data }) => {
            const ids = data.selected.map((item) => item.id);
            const res = await batch({ ids, type: 'deactivate' });
            return !!res.error;
          }),
          props: {
            text: t('table.remove_many'),
            type: 'danger',
          },
        },
      ],
      customActions: [
        {
          emits: 'deselect-and-update',
          component: () => SetSecondCategoryToMany,
        },
      ],
    },
    emptyText: t('table.not_found'),
    rowLink: (row) => (
      {
        name: 'product',
        params: { id: row.id },
      }
    ),
    columns: [
      {
        prop: 'title',
        label: t('table.title'),
        sortable: true,
      },
      {
        prop: 'sku',
        label: t('table.sku'),
        sortable: true,
      },
      {
        prop: 'brand',
        label: t('table.brand'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue as EntityTable['brand'])?.title || 'Не указан',
        },
      },
      {
        prop: 'supplier',
        label: t('table.supplier'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue as EntityTable['supplier'])?.title || 'Не указан',
        },
      },
      {
        prop: 'productImages',
        label: t('table.image'),
        view: {
          type: 'image',
          formatter: (_, row) => (row.productImages?.length ? row.productImages.map((img) => img) : ['/empty.png']),
        },
      },
      {
        prop: 'active',
        label: t('table.active'),
        width: 150,
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue ? 'Да' : 'Нет'),
        },
      },
    ],
  }),

  filters: filtersFormController<EntityTable>({
    saveToLocalStorage: true,

    actions: [
      {
        type: 'link',
        to: () => ({ name: 'product', params: { id: 'new' } }),
        props: {
          type: 'primary',
          plain: true,
          text: t('table.create_new'),
        },
      },
    ],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      supplier: null,
      brand: null,
      category: null,
      title: '',
      active: null,
    },

    sort: {
      title: '',
    },

    layout: [
      {
        type: 'select',
        name: 'supplier',
        dataType: 'string',
        options: [],
        props: {
          filterable: true,
          remote: true,
          clearable: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'title',
          remoteMethod: supplierOnlyHasItemsSearch,
          loadRemoteMethodOnFocus: true,
          placeholder: t('table.supplier'),
        },
      },
      {
        type: 'select',
        name: 'brand',
        dataType: 'string',
        options: [],
        props: {
          filterable: true,
          remote: true,
          clearable: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'title',
          remoteMethod: brandSearchOnlyHasItems,
          loadRemoteMethodOnFocus: true,
          placeholder: t('table.brand'),
        },
      },
      {
        type: 'select',
        name: 'category',
        dataType: 'string',
        options: [],
        props: {
          filterable: true,
          remote: true,
          clearable: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'title',
          remoteMethod: categoryOnlyActiveListSearch,
          loadRemoteMethodOnFocus: true,
          placeholder: t('table.category'),
        },
      },
      {
        type: 'input',
        name: 'title',
        props: {
          placeholder: t('table.search'),
          inputDelay: 400,
        },
      },
      {
        type: 'switch',
        name: 'active',
        dataType: 'boolean',
        props: {
          activeLabel: baseT('filters.active'),
        },
      },
    ],
  }),
});
