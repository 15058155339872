import { ft } from '~/utils/feature-translate';

export const ru = {
  pages: {
    sidebar_title: 'Основное',
    sidebar_title_msk: 'Москва',
    card_title: 'Информация о заказе',
  },
  form: {
    print_deadline: 'Печать – сроки нанесения и поставки',
    minimal_order: 'Минимальный заказ',
    payment_types: 'Варианты оплаты',
    delivery_info: 'Доставка товара',
  },
};

export const t = ft(ru, 'content.order_info.');
