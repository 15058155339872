import {
  actionTable, filtersFormController, tableController, tablePageController,
} from 'magner';
import { baseT } from 'configs/translation/base';
import type { LegalPersonList } from './types';
import { read, batch } from './requests';
import { t } from './ru';

export const tableConfig = tablePageController({
  header: {
    title: t('table.title'),
    tabs: [
      {
        label: t('form.tabs.user'),
        link: { name: 'user' },
        active: false,
      },
      {
        label: t('form.tabs.companies'),
        link: { name: 'resident-companies' },
        active: true,
      },
    ],
  },

  request: read,
  table: tableController<LegalPersonList>({
    rowSelectable: {
      rowIdKey: 'id',
      actions: [
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<LegalPersonList>(async ({ data }) => {
            const ids = data.selected.map((item) => item.id);
            const res = await batch({ ids });
            return !!res.error;
          }),
          props: {
            text: t('table.remove_many'),
            type: 'danger',
          },
        },
      ],
    },
    emptyText: t('table.not_found'),
    rowLink: (row) => {
      const userId = window.location.pathname.replace('/users/', '').replace(/\/companies.*/, '');
      return { name: 'legal-person', params: { id: row.id }, query: { user: userId } };
    },
    columns: [
      {
        prop: 'shortTitle',
        label: t('table.short_title'),
      },
      {
        prop: 'address',
        showOverflowTooltip: true,
        label: t('table.address'),
      },
      {
        prop: 'bankTitle',
        label: t('table.bank_title'),
      },
      {
        prop: 'inn',
        label: t('table.inn'),
      },
      {
        prop: 'checkingAccount',
        label: t('table.checking_account'),
      },
    ],
  }),
  filters: filtersFormController<LegalPersonList>({
    saveToLocalStorage: true,

    actions: [
      {
        type: 'link',
        to: (route) => ({ name: 'legal-person', params: { id: 'new' }, query: { user: route.params.id } }),
        props: {
          type: 'primary',
          text: baseT('form.create_new_button'),
        },
      },
    ],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      name: '',
      phone: '',
    },

    sort: {},

    layout: [],
  }),
});
