import { USER_ROLES } from 'features/users/users';

const getUserRole = (roles: Array<USER_ROLES>): USER_ROLES.Admin | USER_ROLES.AdminManager | null => {
  if (roles.includes(USER_ROLES.Admin)) {
    return USER_ROLES.Admin;
  }

  if (roles.includes(USER_ROLES.AdminManager)) {
    return USER_ROLES.AdminManager;
  }

  return null;
};

export default getUserRole;
