import { request } from '~/utils/request';

export interface FileUploadResponse {
  id: string,
  downloadUrl: string,
  thumb1600: string,
  alt: null | string, // хз зачем это добавили, лучше не использовать тк alt изображений зависит от контекста использования, а не места загрузки
}

type Context = 'image' | 'vector' | 'default' | 'userAvatar' | 'product_image';

export const fileUpload = (file: File, context: Context) => {
  const fileCreate = request.custom<FileUploadResponse, File>(async ({ api, data }) => await api
    .instance
    .url('/api/file/upload')
    .formData({
      file: data,
      context,
    })
    .post()
    .unauthorized(() => window.location.replace('/login'))
    .json()
    .then((response) => {
      const uploaded = (response as { data: { id: string } })?.data;
      return uploaded?.id ? { data: uploaded } : { error: 'Не удалось загрузить файл' };
    })
    .catch(() => ({
      error: 'Не удалось загрузить файл',
    })) as any);

  return fileCreate(file);
};

export const saveImage = request
  .custom<FileUploadResponse, File>(async ({ data }) => {
    const fileUploadType = data.type.includes('svg') ? 'vector' : 'image';
    return fileUpload(data, fileUploadType);
  });

export const saveImages = request
  .custom<string[], File[]>(async ({ data }) => {
    const all = await Promise.all(data.map((file) => fileUpload(file, 'image')));
    const filtered = all.filter((res) => res.data?.thumb1600).map((res) => res.data?.thumb1600) as string[];
    return { data: filtered };
  });

export const saveImageContext = (context: Context) => request
  .custom<FileUploadResponse, File>(async ({ data }) => fileUpload(data, context));
