import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import type { UserList, UserResponse, UserCreate } from './types';
import { filterRequestStrings } from '~/utils/filter-request-strings';

const BASE = '/api/admin/user';

export const read = request.table<UserList>(async ({ data }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `page=${activePage}&limit=${data.pagination.items || 25}`;

  const search = data.filters.name ? `&filters[0][id]=search&filters[0][value]=${data.filters.name}` : '';

  const sortCreatedAt = data.sort.created_at ? `&sort[0][id]=createdAt&sort[0][value]=${data.sort.created_at}` : '';

  return getListResponse<UserList>(`${BASE}/list?${pagination}${sortCreatedAt}${search}`, activePage, data.filters.activated);
});

export const get = request.card<UserResponse, UserCreate>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: UserResponse }>(`${BASE}/read/${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return {
    data: {
      ...res.data?.data,
      email: res.data?.data.login,
      password: '',
      shipmentAddresses: res.data?.data.shipmentAddresses?.map((item) => ({ id: item.id, value: item.address })),
    },
  };
});

export const create = request.card<UserResponse, UserCreate>(async ({
  api, data, parseError, router,
}) => {
  filterRequestStrings(data.data);
  const body = {
    ...data.data,
  };

  if (Array.isArray(body.avatar)) {
    body.avatar = body.avatar[0];
  }

  if (!body.additionalPhone) {
    delete body.additionalPhone;
  } else {
    body.additionalPhone = +body.additionalPhone;
  }

  const res = await api.post<{ data: UserResponse }>(`${BASE}/create`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  router.push({ name: 'user', params: { id: res.data.data.id } });
  return { data: res.data?.data };
});

export const update = request.card<UserResponse, UserCreate>(async ({ api, data, parseError }) => {
  filterRequestStrings(data.data);
  if (data.data.phone) {
    data.data.phone = data.data.phone.replace(/[^0-9+]/g, '');
  }

  const body = {
    ...data.data,
  };

  if (!body.additionalPhone) {
    delete body.additionalPhone;
  } else {
    body.additionalPhone = +body.additionalPhone;
  }

  if (Array.isArray(body.avatar)) {
    if (body.avatar.length) {
      body.avatar = body.avatar[0];
    } else {
      body.avatar = null;
    }
  } else if (typeof body.avatar === 'string') {
    delete body.avatar;
  }

  if (!body.password) delete body.password;
  if (!body.birthdate) {
    delete body.birthdate;
  } else {
    // @ts-ignore
    body.birthdate = body.birthdate.slice(0, 10);
  }

  const res = await api.patch<{ data: UserResponse }>(`${BASE}/update/${data.id}`, body);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.data };
});

export const batch = request.custom<boolean, { ids: string[] }>(async ({ data, api, parseError }) => {
  const res = await api.post(`${BASE}/batch/delete`, { ids: data.ids });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: true,
  };
});

export const remove = request.card<boolean, UserCreate>(async ({ data }) => batch({
  ids: [data.id as string],
}));
